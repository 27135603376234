import {Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DatePipe} from "@angular/common";
import {Store} from "@ngrx/store";
import * as fromTasks from "../../../../home/tasks/store";
import * as Tasks from '../../../../home/tasks/store/actions';
import {LoggingService} from "../../../logging.service";
const moment = require('moment');

@Component({
  selector: "TasksLine",
  templateUrl: './tasks-line.component.html',
  styleUrls: ['./tasks-line.component.scss'],
})
export class TasksLine implements OnInit, OnDestroy, OnChanges  {

  @Input() task: any;
  @Input() editTask: any
  @Input() role: string
  public open: boolean = false;

  constructor(private store: Store<fromTasks.State>,
              private log: LoggingService) {}

  ngOnInit(){
  }

  ngAfterViewInit(){
  }

  ngOnDestroy(){
  }

  ngOnChanges(changes:SimpleChanges){
  }

  deleteTask() {
    this.open = !this.open;
    this.log.Action("delete-task", {taskId: this.task.id})
    this.store.dispatch(new Tasks.DeleteTask(this.task.id));
  }

  approveTask() {
    this.log.Action("update-task-status", {status:"approved"})
    this.store.dispatch(new Tasks.EditTask(this.task.id, {
      id: this.task.id,
      status: "approved",
    }));
  }

  rejectTask(){
    this.log.Action("update-task-status", {status:"rejected"})
    this.store.dispatch(new Tasks.EditTask(this.task.id, {
      id: this.task.id,
      status: "rejected",
    }));
  }

  openTask(){
    this.log.Action("update-task-status", {status:"open"})
    this.store.dispatch(new Tasks.EditTask(this.task.id, {
      id: this.task.id,
      status: "open",
    }));
  }

  truncate(fullStr, strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || '...';

    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow/2),
      backChars = Math.floor(charsToShow/2);

    return fullStr.substr(0, frontChars) +
      separator +
      fullStr.substr(fullStr.length - backChars);
  }

  shortenString(text: string, length: number) : string {
    const trimmedString = text.length > length ? text.substring(0, length - 3) : text;
    return trimmedString
  }
}


import {of as observableOf,  Observable } from 'rxjs';

import {catchError, switchMap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import * as Action from '../store/actions';
import { ProfileService } from '../profile.service';
import { State } from '../../../app.reducer';

@Injectable()
export class Effects {

    /* Retrieve all invoices from current user */

    @Effect()
    requestProfile: Observable<Action.RequestProfileFailure | Action.RequestProfileSuccess> = this.actions$
        .ofType(Action.REQUEST_PROFILE).pipe(
        switchMap((action: Action.RequestProfile) => {
          return this.profileService.getProfile().pipe(map((profile) => {
            return new Action.RequestProfileSuccess(profile);
          }),
          catchError(e => {
            return observableOf(new Action.RequestProfileFailure(e));
          }),);
        }));

    /* Update profile */

    @Effect()
    updateProfile: Observable<Action.UpdateProfileFailure | Action.UpdateProfileSuccess> = this.actions$
        .ofType(Action.UPDATE_PROFILE).pipe(
        switchMap((action: Action.UpdateProfile) => {
          return this.profileService.updateProfile(action.payload).pipe(map(profile => {
            //mixpanel.track("updateProfile", {profile: action.payload});
            return new Action.UpdateProfileSuccess(profile);
          }),
          catchError((e) => {
            //mixpanel.track("updateProfileFailed", {profile: action.payload, error: e});
            return observableOf(new Action.UpdateProfileFailure(e.error));
          }),);
        }));

    /* Update settings */

    @Effect()
    updateSettings: Observable<Action.UpdateSettingsFailure | Action.UpdateSettingsSuccess> = this.actions$
      .ofType(Action.UPDATE_SETTINGS).pipe(
        switchMap((action: Action.UpdateSettings) => {
          return this.profileService.updateSettings(action.payload).pipe(map(settings => {
              //mixpanel.track("updateSettings", {settings: action.payload});
              return new Action.UpdateSettingsSuccess(settings);
            }),
            catchError((e) => {
              //mixpanel.track("updateSettingsFailed", {settings: action.payload, error: e});
              return observableOf(new Action.UpdateSettingsFailure(e.error));
            }),);
        }));

    /* Update credentials */

    @Effect()
    updateCredentials: Observable<Action.UpdateCredentialsFailure | Action.UpdateCredentialsSuccess> = this.actions$
        .ofType(Action.UPDATE_CREDENTIALS).pipe(
        switchMap((action: Action.UpdateCredentials) => {
          return this.profileService.updateCredentials(action.payload).pipe(map(credentials => {
            //mixpanel.track("updateCredentials");
            return new Action.UpdateCredentialsSuccess(credentials);
          }),
          catchError((e) => {
            //mixpanel.track("updateCredentialsFailed", {error: e});
            return observableOf(new Action.UpdateCredentialsFailure(e.error));
          }),);
        }));


    /* Retrieve all invoices from current user */

    @Effect()
    requestInvoices: Observable<Action.RequestInvoicesFailure | Action.RequestInvoicesSuccess> = this.actions$
        .ofType(Action.REQUEST_INVOICES).pipe(
        switchMap((action: Action.RequestInvoices) => {
          return this.profileService.getInvoices().pipe(map((invoices) => {
            return new Action.RequestInvoicesSuccess(invoices);
          }),
          catchError(e => {
            return observableOf(new Action.RequestInvoicesFailure(e));
          }),);
        }));

    /* Add a new credit card */

    @Effect()
    updateCard: Observable<Action.UpdateCardSuccess | Action.UpdateCardFailure> = this.actions$
        .ofType(Action.UPDATE_CARD).pipe(
        switchMap((action: Action.UpdateCard) => {
          return this.profileService.updateCard(action.payload).pipe(map(card => {
            //mixpanel.track("updateCard");
            return new Action.UpdateCardSuccess(card);
          }),
          catchError((e) => {
            //mixpanel.track("updateCardFailed", {error: e});
            return observableOf(new Action.UpdateCardFailure(e.error.error));
          }),);
        }));

    /* Remove credit card */

    @Effect()
    removeCard: Observable<Action.DeleteCardSuccess | Action.DeleteCardFailure> = this.actions$
        .ofType(Action.DELETE_CARD).pipe(
        switchMap((action: Action.DeleteCard) => {
          return this.profileService.deleteCard().pipe(map(card => {
            //mixpanel.track("deleteCard");
            return new Action.DeleteCardSuccess(card);
          }),
          catchError((e) => {
            //mixpanel.track("deleteCardFailed", {error: e});
            return observableOf(new Action.DeleteCardFailure(e.error.error));
          }),);
        }));

    /* Get QR code for 2FA */

    @Effect()
    getQRCode: Observable<Action.GetQRCodeFailure | Action.GetQRCodeSuccess> = this.actions$
        .ofType(Action.GET_QRCODE).pipe(
        switchMap((action: Action.GetQRCode) => {
          return this.profileService.getQRCode().pipe(map((qrcode) => {
            return new Action.GetQRCodeSuccess(qrcode);
          }),
          catchError(e => {
            return observableOf(new Action.GetQRCodeFailure(e));
          }),);
        }));

    /* Validate QR code for 2FA */

    @Effect()
    validateQRCode: Observable<Action.ValidateQRCodeFailure | Action.ValidateQRCodeSuccess> = this.actions$
        .ofType(Action.VALIDATE_QRCODE).pipe(
        switchMap((action: Action.ValidateQRCode) => {
          return this.profileService.validateQRCode(action.payload).pipe(map((qrcode) => {
            return new Action.ValidateQRCodeSuccess(qrcode);
          }),
          catchError(e => {
            return observableOf(new Action.ValidateQRCodeFailure(e));
          }),);
        }));

    /* Disable 2FA */

    @Effect()
    disableMFA: Observable<Action.DisableMFAFailure | Action.DisableMFASuccess> = this.actions$
        .ofType(Action.DISABLE_MFA).pipe(
        switchMap((action: Action.DisableMFA) => {
          return this.profileService.disableMFA().pipe(map((mfa) => {
            return new Action.DisableMFASuccess(mfa);
          }),
          catchError(e => {
            return observableOf(new Action.DisableMFAFailure(e));
          }),);
        }));

    constructor(private profileService: ProfileService,
                private store$: Store<State>,
                private actions$: Actions,
                private router: Router,
                private route: ActivatedRoute) {}
}

import {of as observableOf,  Observable } from 'rxjs';
import {catchError, switchMap, map, mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as Action from '../store/actions';
import * as MediaAction from '../../media/store/actions';
import { WatchlistService } from '../watchlist.service';
import { State } from '../../../app.reducer';

@Injectable()
export class Effects {

    /* Retrieve all notifications */

    @Effect()
    requestWatchlists: Observable<Action.RequestWatchlistsFailure | Action.RequestWatchlistsSuccess> = this.actions$
        .ofType(Action.REQUEST_WATCHLIST).pipe(
        switchMap((action: Action.RequestWatchlists) => {
          return this.watchlistService.getNotifications().pipe(map((notifications) => {
            return new Action.RequestWatchlistsSuccess(notifications);
          }),
          catchError(e => {
            return observableOf(new Action.RequestWatchlistsFailure(e));
          }),);
        }));



    /* Edit notification from watchlist */

    @Effect()
    editWatchlist: Observable<Action.EditWatchlistFailure | Action.EditWatchlistSuccess> = this.actions$
        .ofType(Action.EDIT_WATCHLIST).pipe(
        switchMap((action: Action.EditWatchlist) => {
          return this.watchlistService.editNotification(action.id, action.payload).pipe(map((notification) => {
            return new Action.EditWatchlistSuccess(notification.data);
          }),
          catchError(e => {
            return observableOf(new Action.EditWatchlistFailure(e));
          }),);
        }));
        

    /* Retrieve filtered watchlist */

    @Effect()
    requestFilteredWatchlists: Observable<Action.RequestFilteredWatchlistsFailure | Action.RequestFilteredWatchlistsSuccess> = this.actions$
      .ofType(Action.REQUEST_FILTERED_WATCHLIST).pipe(
        switchMap((action: Action.RequestFilteredWatchlists) => {
          return this.watchlistService.getFilteredNotifications(action.payload).pipe(map((tasks) => {
              return new Action.RequestFilteredWatchlistsSuccess(action.payload, tasks);
            }),
            catchError(e => {
              return observableOf(new Action.RequestFilteredWatchlistsFailure(e));
            }),);
        }));

    /* Get watchlist statistics */

    @Effect()
    getWatchlistsStatistics: Observable<Action.GetWatchlistsStatisticsFailure | Action.GetWatchlistsStatisticsSuccess> = this.actions$
      .ofType(Action.GET_WATCHLIST_STATISTICS).pipe(
        switchMap((action: Action.RequestWatchlists) => {
          return this.watchlistService.getNotificationsStatistics().pipe(map((tasks) => {
              return new Action.GetWatchlistsStatisticsSuccess(tasks.data);
            }),
            catchError(e => {
              return observableOf(new Action.GetWatchlistsStatisticsFailure(e));
            }),);
        }));

    /* Delete from watch list */

    @Effect()
    deleteWatchlist: Observable<Action.DeleteWatchlistFailure | Action.DeleteWatchlistSuccess> = this.actions$
        .ofType(Action.DELETE_WATCHLIST).pipe(
        switchMap((action: Action.DeleteWatchlist) => {
          return this.watchlistService.deleteTask(action.id).pipe(map((tasks) => {
            return new Action.DeleteWatchlistSuccess(tasks.data);
          }),
          catchError(e => {
            return observableOf(new Action.DeleteWatchlistFailure(e));
          }),);
        }));


    /* Mark all events from watchlist as read */

    @Effect()
    readAll: Observable<Action.ReadAllFailure | Action.ReadAllSuccess> = this.actions$
        .ofType(Action.READ_ALL).pipe(
        switchMap((action: Action.ReadAll) => {
          return this.watchlistService.readAll().pipe(map((data) => {
            return new Action.ReadAllSuccess(data);
          }),
          catchError(e => {
            return observableOf(new Action.ReadAllFailure(e));
          }),);
        }));

      
    constructor(private watchlistService: WatchlistService,
                private store$: Store<State>,
                private actions$: Actions) {}
}

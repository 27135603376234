import {Component, OnInit, OnDestroy, Input, Inject, ViewChild, ElementRef} from '@angular/core';
import { v4 as uuidv4 } from "uuid";
import mixpanel from 'mixpanel-browser/src/loader-module';
import {IMqttMessage, MqttService} from "ngx-mqtt";
import {kerberosConfig} from "../../../../../../environments/environment";

@Component({
  selector: "WebRTCStream",
  templateUrl: './webrtcstream.component.html',
  styleUrls: ['./webrtcstream.component.scss'],
})
export class WebRTCStream implements OnInit, OnDestroy  {

  @Input("deviceKey") deviceKey: string;
  @Input("cloudKey") cloudKey: string;
  @Input("changeStatus") changeStatus: any;
  @ViewChild('target', { static: true }) target: ElementRef;

  public config: any;
  public turnServer: any = kerberosConfig.turnServer;
  public pc: any;
  public cuuid: any;
  public candidatesQueue: any;

  public subscription: any = null;
  public subscriptionICE: any = null;
  public sendChannel: any;

  public status: string = 'pending';
  public livestream;
  public liveStreamObservable;
  public liveLegacyStreamObservable;
  public pollingObservables: Array<any> = [];
  public livestreamStarted: boolean = false;


  constructor(
    @Inject('mqttNew') private mqttService: MqttService) {}

    ngOnInit(){
      this.updateStatus('pending');
    //mixpanel.track("openHDStream");
    this.handleNegotiationNeededEvent = this.handleNegotiationNeededEvent.bind(this);
  }

  ngAfterViewInit(){
    this.startStream();
  }

  updateStatus(status) {
    this.status = status;
    this.changeStatus(status);
  }



  startStream() {
    const stunServer = "stun:"+ this.turnServer.split("turn:")[1];
    this.config = {
      //iceTransportPolicy: 'relay',
      iceServers: [
        {
          urls: [stunServer],
        },
        {
          urls: [this.turnServer],
          username: kerberosConfig.turnUsername,
          credential: kerberosConfig.turnPassword,
        }
      ],
    };
    //console.log("===== STUN/TURN config =======")
    //console.log(this.config)

    // Setup WebRTC component.
    this.pc = new RTCPeerConnection(this.config);

    //console.log("===== PC config =======")
    //console.log(this.pc)

    this.pc.onnegotiationneeded = this.handleNegotiationNeededEvent;
    this.pc.oniceconnectionstatechange = e => {
      this.status = this.pc.iceConnectionState;
      this.changeStatus(this.status)
      if(this.status === 'disconnected') {
        this.disconnectStream();
        //console.log("HD stream disconnected.")
        const interval = setInterval(() => {
          if(this.status === "checking") {
            //console.log("Trying to reconnect.")
            this.startStream()
          } else if(this.status === "connected") {
            this.changeStatus(this.status)
            //console.log("Connection reestablished.")
            clearInterval(interval)
          }
        }, 3000);
      }
    }

    this.pc.ontrack = (event) => {
      this.target.nativeElement.srcObject = event.streams[0];
    };

    this.cuuid = uuidv4();
    this.candidatesQueue = [];

    this.subscriptionICE = this.mqttService.observe(`${this.deviceKey}/${this.cuuid}/candidate/edge`).subscribe((message: IMqttMessage) => {
      const candidate = JSON.parse(message.payload.toString());
      if(this.pc.currentRemoteDescription) {
        this.pc.addIceCandidate(candidate).catch(e => {
        });
      } else {
        this.candidatesQueue.push(candidate);
      }
    });

    this.pc.onicecandidate = (event) => {

      //console.log("===== PC onicecandidate =======")
      //console.log(`${this.deviceKey}/${this.cuuid}/candidate/cloud`)

      if (event.candidate) {
        // Send the candidate to the remote peer
        this.mqttService.unsafePublish(`candidate/cloud`, JSON.stringify({
          candidate: event.candidate.candidate,
          cuuid: this.cuuid,
          cloud_key: this.deviceKey,
        }), {qos: 0});
        this.mqttService.unsafePublish(`${this.deviceKey}/${this.cuuid}/candidate/cloud`, JSON.stringify(event.candidate.candidate), {qos: 0});
      } else {
        // All ICE candidates have been sent
      }
    }

    this.subscription = this.mqttService.observe(`${this.deviceKey}/${this.cuuid}/answer`).subscribe((message: IMqttMessage) => {

      this.pc.setRemoteDescription(
        new RTCSessionDescription({
          type: "answer",
          sdp: atob(message.payload.toString()),
        })
      ).then(() => {

        //console.log("===== PC addIceCandidate =======")

        for(let i = 0; i < this.candidatesQueue.length; i++){
          this.pc.addIceCandidate(this.candidatesQueue[i]).catch(e => {
            ////console.log("Failure during addIceCandidate(): " + e);
          });
        }
      });

    });

    // This will fire the whole process!
    this.pc.addTransceiver("video", {
      direction: "sendrecv",
    });
    this.pc.addTransceiver("audio", {
      direction: "sendrecv",
    });
  }

  handleNegotiationNeededEvent() {
    return this.pc.createOffer({
      offerToReceiveAudio: true,
      offerToReceiveVideo: true,
      iceRestart: true,
    }).then(offer => {
      return this.pc.setLocalDescription(offer);
    }).then(() => {
      const myjson = {
        cuuid: this.cuuid,
        sdp: btoa(this.pc.localDescription.sdp),
      };
      this.mqttService.unsafePublish(`${this.deviceKey}/register`, JSON.stringify(myjson), {qos: 0});
    }).catch(error => console.log(error));
  }

  disconnectStream() {
    if(this.sendChannel){
      this.sendChannel.close();
    }
    if(this.pc){
      this.pc.close();
    }
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
    if(this.subscriptionICE) {
      this.subscriptionICE.unsubscribe();
    }
    this.updateStatus('checking');
  }

  ngOnDestroy() {
    this.disconnectStream();
  }
}


import {of as observableOf,  Observable } from 'rxjs';
import {catchError, switchMap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import * as Action from '../store/actions';
import { SitesService } from '../sites.service';
import { State } from '../../../app.reducer';
import mixpanel from 'mixpanel-browser/src/loader-module';

@Injectable()
export class Effects {

    /* Retrieve all sites */

    @Effect()
    requestSites: Observable<Action.RequestSitesFailure | Action.RequestSitesSuccess> = this.actions$
        .ofType(Action.REQUEST_SITES).pipe(
        switchMap((action: Action.RequestSites) => {
          return this.sitesService.getSites().pipe(map((sites) => {
            return new Action.RequestSitesSuccess(sites);
          }),
          catchError(e => {
            return observableOf(new Action.RequestSitesFailure(e));
          }),);
        }));

    /* Find a site */

    @Effect()
    findSite: Observable<Action.FindSiteFailure | Action.FindSiteSuccess> = this.actions$
        .ofType(Action.FIND_SITE).pipe(
        switchMap((action: Action.FindSite) => {
          return this.sitesService.getSite(action.payload).pipe(map((site) => {
            return new Action.FindSiteSuccess(site);
          }),
          catchError(e => {
            return observableOf(new Action.FindSiteFailure(e));
          }),);
        }));

    /* Find all devices of a site */

    @Effect()
    findDevicesOfSite: Observable<Action.FindDevicesOfSiteFailure | Action.FindDevicesOfSiteSuccess> = this.actions$
      .ofType(Action.FIND_SITE_DEVICES).pipe(
        switchMap((action: Action.FindDevicesOfSite) => {
          return this.sitesService.getDevicesFromSite(action.payload).pipe(map((site) => {
              return new Action.FindDevicesOfSiteSuccess(site);
            }),
            catchError(e => {
              return observableOf(new Action.FindDevicesOfSiteFailure(e));
            }),);
        }));

    /* Add site */

    @Effect()
    addSite: Observable<Action.AddSiteFailure | Action.AddSiteSuccess> = this.actions$
        .ofType(Action.ADD_SITE).pipe(
        switchMap((action: Action.AddSite) => {
          return this.sitesService.addSite(action.payload).pipe(map((sites) => {
            return new Action.AddSiteSuccess(sites);
          }),
          catchError(e => {
            return observableOf(new Action.AddSiteFailure(e));
          }),);
        }));

    /* Edit site */

    @Effect()
    editSite: Observable<Action.EditSiteFailure | Action.EditSiteSuccess> = this.actions$
        .ofType(Action.EDIT_SITE).pipe(
        switchMap((action: Action.EditSite) => {
          return this.sitesService.editSite(action.id, action.payload).pipe(map((sites) => {
            return new Action.EditSiteSuccess(action.id, sites);
          }),
          catchError(e => {
            return observableOf(new Action.EditSiteFailure(e));
          }),);
        }));

    /* Delete site */

    @Effect()
    deleteSite: Observable<Action.DeleteSiteFailure | Action.DeleteSiteSuccess> = this.actions$
        .ofType(Action.DELETE_SITE).pipe(
        switchMap((action: Action.DeleteSite) => {
          return this.sitesService.deleteSite(action.id).pipe(map((sites) => {
            return new Action.DeleteSiteSuccess(sites);
          }),
          catchError(e => {
            return observableOf(new Action.DeleteSiteFailure(e));
          }),);
        }));

    /* Assign devices to site */

    @Effect()
    assignDevices: Observable<Action.AssignDevicesFailure | Action.AssignDevicesSuccess> = this.actions$
        .ofType(Action.ASSIGN_DEVICES).pipe(
        switchMap((action: Action.AssignDevices) => {
          return this.sitesService.assignDevices(action.id, action.payload).pipe(map((sites) => {
            return new Action.AssignDevicesSuccess(sites);
          }),
          catchError(e => {
            return observableOf(new Action.AssignDevicesFailure(e));
          }),);
        }));

    constructor(private sitesService: SitesService,
                private store$: Store<State>,
                private actions$: Actions,
                private router: Router,
                private route: ActivatedRoute) {}
}

import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { fromEvent, interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

declare var videojs : any;

@Component({
  selector: 'video-js',
  styleUrls: ['./video-js.component.scss'],
  templateUrl: './video-js.component.html',
})
export class VideoJsComponent implements OnDestroy {
  @ViewChild('target', { static: true }) target: ElementRef;
  @Input() options: any;
  @Input() hasPlaylist: boolean = false;
  @Input() playlist: any = {};
  @Input() markers: any = [];

  private player: any;
  private properties: any;
  private oldX: number = 0;
  private oldY: number = 0;
  private xDistance: number = 0;
  private yDistance: number = 0;
  private mousePressed: boolean = false;

  constructor(private elementRef: ElementRef) {
    this.setup = this.setup.bind(this);
    this.zoomHandlerWeb = this.zoomHandlerWeb.bind(this);
    this.mouseDownHandlerWeb = this.mouseDownHandlerWeb.bind(this);
    this.mouseUpHandlerWeb = this.mouseUpHandlerWeb.bind(this);
    this.mouseMoveHandlerWeb = this.mouseMoveHandlerWeb.bind(this);
    this.dblClickHandlerWeb = this.dblClickHandlerWeb.bind(this);
    this.disableScroll = this.disableScroll.bind(this);
    this.enableScroll = this.enableScroll.bind(this);
    this.properties = {
      /* zoom */
      currentZoom: 1,
      defaultZoom: 1,
      minZoom: 1,
      maxZoom: 5,

      /*move*/
      moveX: 0,
      moveY: 0,
    }
    // This is used to have a global scrolling state,
    // across the different video players.
    window["env"]["disabledScroll"] = false;
  }

  ngOnChanges(changes){
    if(changes.hasPlaylist) {
      this.hasPlaylist = changes.hasPlaylist.currentValue;
    }
    if(changes.options) {
      this.options = changes.options.currentValue;
    }
    if(changes.playlist) {
      this.playlist = changes.playlist.currentValue;
    }
  }

  ngAfterViewInit() {

    const spriteConfig = {
      interval:1, // TODO should come from environment variables SPRITE_INTERVAL
      width: 240, // TODO should come from environment variables SPRITE_INTERVAL
      height: 135, // TODO should come from environment variables SPRITE_INTERVAL
      downlink: 0,
    }

    const options = {
      ...this.options,
      plugins: {
        vjsdownload:{
          beforeElement: 'playbackRateMenuButton',
          textControl: 'Download video',
          name: 'downloadButton',
        },
        // default thumbnail settings for this player
        spriteThumbnails: spriteConfig,
      }
    };

    options.playbackRates = [0.1, 0.2, 0.5, 1, 1.5, 2, 4];
    this.player = videojs(this.target.nativeElement, options);
    this.player.seekButtons({
      forward: 5,
      back: 5
    });
    this.setup();

    if(this.hasPlaylist) {
      let playlist = [];
      for(let i = 0; i < this.playlist.images.length; i++)
      {
        const media = this.playlist.images[i];
        const metadata = media.metadata;
        if(media.type === "video" && metadata.persisted !== false) {

          const instance = media.instanceName;
          let name = media.time + " (" + instance + ")";

          if(media.analysis &&
            media.analysis.classify &&
            media.analysis.classify.properties &&
            media.analysis.classify.properties.length > 0
          ) {
            const properties = media.analysis.classify.properties;
            const property = properties[0];
            name = property.charAt(0).toUpperCase() + property.slice(1) + " at " + name;
          }

          const video = {
            name,
            sources: [{
              src: media.src,
              type: 'video/mp4',
              spriteThumbnails: {
                url: media.spriteUrl
              }
            }],
          }
          playlist.push(video);
        }
      }
      this.player.playlistUi();
      this.player.playlist(playlist);

      this.player.on('playlistitem', () => {
        const currentItem = this.player.playlist.currentItem();
        const item = playlist[currentItem];
        this.videoStart(item);
        this.player.zoomrotate({
          rotate: 0,
          zoom: this.options.defaultZoom,
          auto: false,
          moveX: "0px",
          moveY: "0px",
        });
      });
    } else {

    }

    //load the marker plugin
    if(this.markers && this.markers.length > 0) {
      this.player.markers({
        markerStyle: {
          'width':'8px',
          'background-color': 'var(--hub)'
        },
        markers: this.markers,
      });
    }
  }

  videoStart(url) {
    //mixpanel.track("playVideoDashboard", {
    // video: url
    //});
  }

  private zoomHandlerWeb (event: WheelEvent) {
    if (event.shiftKey) {

      if(window["env"]["disabledScroll"] == false) {
        this.disableScroll();
        window["env"]["disabledScroll"] = true;
      }

      this.player.pause();
      const isWheelUp = event.deltaY < 0;
      const zoomFocus = (isWheelUp ? -1 : 1)/10
      this.properties.currentZoom = this.properties.currentZoom - zoomFocus;
      if(this.properties.currentZoom < this.properties.minZoom) {
        this.properties.currentZoom = this.properties.minZoom;
      }
      if(this.properties.currentZoom > this.properties.maxZoom) {
        this.properties.currentZoom = this.properties.maxZoom;
      }
      this.zoomAndRotate();
    }
  }

  private mouseDownHandlerWeb(event) {
    this.mousePressed = true;
    this.oldX = event.pageX;
    this.oldY = event.pageY;
    event.preventDefault();
  }

  private mouseUpHandlerWeb(event) {
    this.mousePressed = false;
    if(event.target.classList.contains('video-js') && this.oldX - event.pageX < 5 && this.oldY - event.pageY < 5) {
      if(this.player.paused()) {
        this.player.play();
      } else {
        this.player.pause();
      }
    }
  }

  private mouseMoveHandlerWeb(event){
    if (event.shiftKey) {
      if(this.mousePressed && event.target.classList.contains('video-js') &&
        this.oldY > 0 && this.oldX > 0 && this.oldX != event.pageX && this.oldY != event.pageY) {
        this.player.pause();
        this.xDistance += -1 * (this.oldX - event.pageX)
        this.yDistance += -1 * (this.oldY - event.pageY)
        this.oldX = event.pageX;
        this.oldY = event.pageY;
        this.zoomAndRotate();
      }
      event.preventDefault();
    }
  }

  private dblClickHandlerWeb(event) {
    event.preventDefault();
    this.player.pause();
    this.xDistance = 0;
    this.yDistance = 0;
    this.properties.currentZoom = 1;
    this.zoomAndRotate();
    event.preventDefault();
  }

  private zoomAndRotate(){
    this.player.zoomrotate({
      rotate: 0,
      zoom: this.properties.currentZoom,
      auto: false,
      moveX: this.xDistance+ "px",
      moveY: this.yDistance + "px",
    });
  }

  private setup() {
    const $video = this.player.el();
    if (!videojs.browser.TOUCH_ENABLED) {
      $video.addEventListener('wheel', this.zoomHandlerWeb);
      const scrolling = fromEvent($video, 'wheel');
      const result = scrolling.pipe(debounce(ev => interval(500)));
      result.subscribe(x => {
        this.enableScroll()
      });

      $video.addEventListener('mousedown', this.mouseDownHandlerWeb);
      $video.addEventListener('mouseup', this.mouseUpHandlerWeb);
      $video.addEventListener('mousemove', this.mouseMoveHandlerWeb);
      $video.addEventListener('dblclick', this.dblClickHandlerWeb);
    }
  }

  private disableScroll() {
    // Get the current page scroll position
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    // if any scroll is attempted,
    // set this to the previous value
    window["env"]["disabledScroll"] = true;
    window.onscroll = function() {
      window.scrollTo(scrollLeft, scrollTop);
    };
  }

  private enableScroll() {
    window["env"]["disabledScroll"] = false;
    window.onscroll = function() {};
  }

  public setTime(time) {
    this.player.currentTime(time);
  }

  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }
}

import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {Md5} from "ts-md5";

@Component({
  selector: "AccountsList",
  templateUrl: './accounts-list.component.html',
  styleUrls: ['./accounts-list.component.scss'],
})
export class AccountsList implements OnInit, OnDestroy  {

  @Input() user: any;
  @Input() accounts: any;
  @Input() editAccount: any
  @Input() deleteAccount: any

  public gravatarSize: number = 40;

  constructor() {}

  ngOnInit(){
  }

  ngAfterViewInit(){
  }

  ngOnDestroy(){
  }

  public getGravatar(email) {
    return 'https://www.gravatar.com/avatar/' + Md5.hashStr(email) + '?s=' + this.gravatarSize + '&d=mm';
  }

  public trackAccount(index: number, account: any) {
    return account.id;
  }
}

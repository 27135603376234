
import {of as observableOf,  Observable } from 'rxjs';

import {map, switchMap, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import * as Action from '../store/actions';
import { AlertsService } from '../alerts.service';
import { State } from '../../../app.reducer';
import mixpanel from 'mixpanel-browser/src/loader-module';
import {LoggingService} from "../../../shared/logging.service";

@Injectable()
export class Effects {

    /* Retrieve all notifications settings from current user */

    @Effect()
    requestNotificationsSettings: Observable<Action.RequestNotificationsSettingsFailure | Action.RequestNotificationsSettingsSuccess> = this.actions$
        .ofType(Action.REQUEST_NOTIFICATIONS_SETTINGS).pipe(
        switchMap((action: Action.RequestNotificationsSettings) => {
          return this.notificationsService.getNotificationSettings().pipe(map((notificationSettings) => {
            return new Action.RequestNotificationsSettingsSuccess(notificationSettings);
          }),
          catchError(e => {
            return observableOf(new Action.RequestNotificationsSettingsFailure(e));
          }),);
        }));

    /* Update a notifications */

    @Effect()
    updateNotificationSettings: Observable<Action.UpdateNotificationsSettingsFailure | Action.UpdateNotificationsSettingsSuccess> = this.actions$
        .ofType(Action.UPDATE_NOTIFICATIONS_SETTINGS).pipe(
        switchMap((action: Action.UpdateNotificationsSettings) => {
          return this.notificationsService.updateNotificationSettings(action.payload).pipe(map((notificationSettings) => {
            this.log.Action("update-alert", {alert:action.payload})
            return new Action.UpdateNotificationsSettingsSuccess(action.payload);
          }),
          catchError(e => {
            return observableOf(new Action.UpdateNotificationsSettingsFailure(e));
          }),);
        }));

    /* Retrieve all custom alerts */

    @Effect()
    requestCustomAlerts: Observable<Action.RequestCustomAlertFailure | Action.RequestCustomAlertSuccess> = this.actions$
        .ofType(Action.REQUEST_CUSTOMALERT).pipe(
        switchMap((action: Action.RequestCustomAlert) => {
          return this.notificationsService.getCustomAlerts().pipe(map((customAlerts) => {
            return new Action.RequestCustomAlertSuccess(customAlerts);
          }),
          catchError(e => {
            return observableOf(new Action.RequestCustomAlertFailure(e));
          }),);
        }));

    /* Create a custom alert */

    @Effect()
    createCustomAlert: Observable<Action.CreateCustomAlertFailure | Action.CreateCustomAlertSuccess> = this.actions$
        .ofType(Action.CREATE_CUSTOMALERT).pipe(
        switchMap((action: Action.CreateCustomAlert) => {
          return this.notificationsService.createCustomAlert(action.payload).pipe(map((customAlerts) => {
            this.log.Action("create-alert", {alert:action.payload})
            return new Action.CreateCustomAlertSuccess(customAlerts);
          }),
          catchError(e => {
            return observableOf(new Action.CreateCustomAlertFailure(e));
          }),);
        }));

    /* Update a custom alert */
    
    @Effect()
    updateCustomAlert: Observable<Action.UpdateCustomAlertFailure | Action.UpdateCustomAlertSuccess> = this.actions$
        .ofType(Action.UPDATE_CUSTOMALERT).pipe(
        switchMap((action: Action.UpdateCustomAlert) => {
          return this.notificationsService.updateCustomAlert(action.payload, action.alertId).pipe(map((customAlerts) => {
            this.log.Action("update-alert", {alert:action.payload})
            return new Action.UpdateCustomAlertSuccess(customAlerts);
          }),
          catchError(e => {
            return observableOf(new Action.UpdateCustomAlertFailure(e));
          }),);
        }));

    /* Remove a custom alert */
    
    @Effect()
    removeCustomAlert: Observable<Action.RemoveCustomAlertFailure | Action.RemoveCustomAlertSuccess> = this.actions$
        .ofType(Action.REMOVE_CUSTOMALERT).pipe(
        switchMap((action: Action.RemoveCustomAlert) => {
          return this.notificationsService.deleteCustomAlert(action.alertId).pipe(map((customAlerts) => {
            this.log.Action("remove-alert", {alert:action.alertId})
            return new Action.RemoveCustomAlertSuccess(customAlerts);
          }),
          catchError(e => {
            return observableOf(new Action.RemoveCustomAlertFailure(e));
          }),);
        }));


    /* Toggle (enable/disable) a custom alert */
    
    @Effect()
    toggleCustomAlert: Observable<Action.ToggleCustomAlertFailure | Action.ToggleCustomAlertSuccess> = this.actions$
        .ofType(Action.TOGGLE_CUSTOMALERT).pipe(
        switchMap((action: Action.ToggleCustomAlert) => {
          return this.notificationsService.toggleCustomAlert(action.enabled, action.alertId).pipe(map((customAlerts) => {
            this.log.Action("toggle-alert", {alert:action.alertId})
            return new Action.ToggleCustomAlertSuccess(customAlerts);
          }),
          catchError(e => {
            return observableOf(new Action.ToggleCustomAlertFailure(e));
          }),);
        }));
    
    constructor(private notificationsService: AlertsService,
                private store$: Store<State>,
                private actions$: Actions,
                private log: LoggingService) {}
}

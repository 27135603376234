import { Action } from '@ngrx/store';

export const UPDATE_DEVICE_ACTIVITY = '[Devices] Update Device Activity';
export const RESET = '[Devices] Reset Devices';
export const REQUEST_DEVICES = '[Devices] Requesting Devices';
export const REQUEST_DEVICES_SUCCESS = '[Devices] Requesting Devices Success';
export const REQUEST_DEVICES_FAILURE = '[Devices] Requesting Devices Failure';
export const REQUEST_MEDIA_DEVICES = '[Devices] Requesting Media from Devices';
export const REQUEST_MEDIA_DEVICES_SUCCESS = '[Devices] Requesting Media from Devices Success';
export const REQUEST_MEDIA_DEVICES_FAILURE = '[Devices] Requesting Media from Devices Failure';
export const GET_DEVICES_STATISTICS = '[Devices] Get Devices Statistics';
export const GET_DEVICES_STATISTICS_SUCCESS = '[Devices] Get Devices Statistics Success';
export const GET_DEVICES_STATISTICS_FAILURE = '[Devices] Get Devices Statistics Failure';
export const REMOVE_DEVICE = '[Devices] Remove Device';
export const REMOVE_DEVICE_SUCCESS = '[Devices] Remove Device Success';
export const REMOVE_DEVICE_FAILURE = '[Devices] Remove Device Failure';
export const REQUEST_LATEST_VERSION = '[Devices] Requesting latest version';
export const REQUEST_LATEST_VERSION_SUCCESS = '[Devices] Requesting latest version Success';
export const REQUEST_LATEST_VERSION_FAILURE = '[Devices] Requesting latest version Failure';
export const FIND_DEVICE = '[Devices] Find a specific device';
export const FIND_DEVICE_SUCCESS = '[Devices] Find a specific device Success';
export const FIND_DEVICE_FAILURE = '[Devices] Find a specific device Failure';
export const UPDATE_DEVICE = '[Devices] Update a specific device';
export const UPDATE_DEVICE_SUCCESS = '[Devices] Update a specific device Success';
export const UPDATE_DEVICE_FAILURE = '[Devices] Update a specific device Failure';
export const MUTE_DEVICE = '[Devices] Mute a specific device';
export const MUTE_DEVICE_SUCCESS = '[Devices] Mute a specific device Success';
export const MUTE_DEVICE_FAILURE = '[Devices] Mute a specific device Failure';
export const UPDATE_DEVICE_PTZ_POSITION = '[Devices] Update a specific device PTZ position';
export const ADD_PRESET = '[Devices] Add a preset';
export const ADD_PRESET_SUCCESS = '[Devices] Add a preset Success';
export const ADD_PRESET_FAILURE = '[Devices] Add a preset Failure';
export const REMOVE_PRESET = '[Devices] Remove a preset';
export const REMOVE_PRESET_SUCCESS = '[Devices] Remove a preset Success';
export const REMOVE_PRESET_FAILURE = '[Devices] Remove a preset Failure';
export const LOAD_CONFIG = '[Devices] Load config';
export const LOAD_CONFIG_SUCCESS = '[Devices] Load config Success';
export const LOAD_CONFIG_FAILURE = '[Devices] Load config Failure';
export const UPDATE_CONFIG = '[Devices] Update config';
export const UPDATE_CONFIG_SUCCESS = '[Devices] Update config Success';
export const UPDATE_CONFIG_FAILURE = '[Devices] Update config Failure';
export const UPDATE_CONFIG_RESET = '[Devices] Update config Reset';

export class UpdateDeviceActivity implements Action {
  readonly type = UPDATE_DEVICE_ACTIVITY;

  constructor(public payload: any) {}
}

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export class RequestDevices implements Action {
  readonly type = REQUEST_DEVICES;

  constructor() {}
}

export class RequestDevicesSuccess implements Action {
  readonly type = REQUEST_DEVICES_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestDevicesFailure implements Action {
  readonly type = REQUEST_DEVICES_FAILURE;

  constructor(public payload?: any) {}
}

export class RequestMediaDevices implements Action {
  readonly type = REQUEST_MEDIA_DEVICES;

  constructor() {}
}

export class RequestMediaDevicesSuccess implements Action {
  readonly type = REQUEST_MEDIA_DEVICES_SUCCESS;
  
  constructor(public payload: any) {}
}

export class RequestMediaDevicesFailure implements Action {
  readonly type = REQUEST_MEDIA_DEVICES_FAILURE;

  constructor(public payload?: any) {}
}

export class GetDevicesStatistics implements Action {
  readonly type = GET_DEVICES_STATISTICS;

  constructor() {}
}

export class GetDevicesStatisticsSuccess implements Action {
  readonly type = GET_DEVICES_STATISTICS_SUCCESS;

  constructor(public payload: any) {}
}

export class GetDevicesStatisticsFailure implements Action {
  readonly type = GET_DEVICES_STATISTICS_FAILURE;

  constructor(public payload?: any) {}
}

export class RemoveDevice implements Action {
  readonly type = REMOVE_DEVICE;

  constructor(public payload?: any) {}
}

export class RemoveDeviceSuccess implements Action {
  readonly type = REMOVE_DEVICE_SUCCESS;

  constructor(public payload: any) {}
}

export class RemoveDeviceFailure implements Action {
  readonly type = REMOVE_DEVICE_FAILURE;

  constructor(public payload?: any) {}
}

export class RequestLatestVersion implements Action {
  readonly type = REQUEST_LATEST_VERSION;

  constructor() {}
}

export class RequestLatestVersionSuccess implements Action {
  readonly type = REQUEST_LATEST_VERSION_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestLatestVersionFailure implements Action {
  readonly type = REQUEST_LATEST_VERSION_FAILURE;

  constructor(public payload?: any) {}
}

export class FindDevice implements Action {
  readonly type = FIND_DEVICE;

  constructor(public payload?: any) {}
}

export class FindDeviceSuccess implements Action {
  readonly type = FIND_DEVICE_SUCCESS;

  constructor(public payload: any) {}
}

export class FindDeviceFailure implements Action {
  readonly type = FIND_DEVICE_FAILURE;

  constructor(public payload?: any) {}
}

export class MuteDevice implements Action {
  readonly type = MUTE_DEVICE;

  constructor(public payload?: any) {}
}

export class MuteDeviceSuccess implements Action {
  readonly type = MUTE_DEVICE_SUCCESS;

  constructor(public payload: any) {}
}

export class MuteDeviceFailure implements Action {
  readonly type = MUTE_DEVICE_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateDevice implements Action {
  readonly type = UPDATE_DEVICE;

  constructor(public payload?: any) {}
}

export class UpdateDeviceSuccess implements Action {
  readonly type = UPDATE_DEVICE_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateDeviceFailure implements Action {
  readonly type = UPDATE_DEVICE_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateDevicePTZPosition implements Action {
  readonly type = UPDATE_DEVICE_PTZ_POSITION;

  constructor(public payload?: any) {}
}

export class AddPreset implements Action {
  readonly type = ADD_PRESET;
  
  constructor(public payload?: any) {}
}

export class AddPresetSuccess implements Action {
  readonly type = ADD_PRESET_SUCCESS;

  constructor(public payload: any) {}
}

export class AddPresetFailure implements Action {
  readonly type = ADD_PRESET_FAILURE;
  
  constructor(public payload?: any) {}
}

export class RemovePreset implements Action {
  readonly type = REMOVE_PRESET;

  constructor(public payload?: any) {}
}

export class RemovePresetSuccess implements Action {
  readonly type = REMOVE_PRESET_SUCCESS;

  constructor(public payload: any) {}
}

export class RemovePresetFailure implements Action {
  readonly type = REMOVE_PRESET_FAILURE;

  constructor(public payload?: any) {}
}

export class LoadConfig implements Action {
  readonly type = LOAD_CONFIG;

  constructor(public payload: any) {}
}

export class LoadConfigSuccess implements Action {
  readonly type = LOAD_CONFIG_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadConfigFailure implements Action {
  readonly type = LOAD_CONFIG_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateConfig implements Action {
  readonly type = UPDATE_CONFIG;

  constructor() {}
}

export class UpdateConfigSuccess implements Action {
  readonly type = UPDATE_CONFIG_SUCCESS;

  constructor() {}
}

export class UpdateConfigFailure implements Action {
  readonly type = UPDATE_CONFIG_FAILURE;

  constructor() {}
}

export class UpdateConfigReset implements Action {
  readonly type = UPDATE_CONFIG_RESET;

  constructor() {}
}

export type Actions =
  | UpdateDeviceActivity
  | Reset
  | RequestDevices
  | RequestDevicesSuccess
  | RequestDevicesFailure
  | RequestMediaDevices
  | RequestMediaDevicesSuccess
  | RequestMediaDevicesFailure
  | GetDevicesStatistics
  | GetDevicesStatisticsSuccess
  | GetDevicesStatisticsFailure
  | RemoveDevice
  | RemoveDeviceSuccess
  | RemoveDeviceFailure
  | RequestLatestVersion
  | RequestLatestVersionSuccess
  | RequestLatestVersionFailure
  | FindDevice
  | FindDeviceSuccess
  | FindDeviceFailure
  | MuteDevice
  | MuteDeviceSuccess
  | MuteDeviceFailure
  | UpdateDevice
  | UpdateDeviceSuccess
  | UpdateDeviceFailure
  | UpdateDevicePTZPosition
  | AddPreset
  | AddPresetSuccess
  | AddPresetFailure
  | RemovePreset
  | RemovePresetSuccess
  | RemovePresetFailure
  | LoadConfig
  | LoadConfigSuccess
  | LoadConfigFailure
  | UpdateConfig
  | UpdateConfigSuccess
  | UpdateConfigFailure
  | UpdateConfigReset;

import {of as observableOf,  Observable } from 'rxjs';
import {catchError, switchMap, map, mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as Action from '../store/actions';
import * as MediaAction from '../../media/store/actions';
import { AnalyticsService } from '../analytics.service';
import { State } from '../../../app.reducer';

@Injectable()
export class Effects {

    /* Retrieve all notifications */

    @Effect()
    requestAnalytics: Observable<Action.RequestAnalyticsFailure | Action.RequestAnalyticsSuccess> = this.actions$
        .ofType(Action.REQUEST_ANALYTICS).pipe(
        switchMap((action: Action.RequestAnalytics) => {
          return this.analyticsService.getStatistics(action.payload).pipe(map((statistics) => {
            return new Action.RequestAnalyticsSuccess(statistics);
          }),
          catchError(e => {
            return observableOf(new Action.RequestAnalyticsFailure(e));
          }),);
        }));


    constructor(private analyticsService: AnalyticsService,
                private store$: Store<State>,
                private actions$: Actions) {}
}


import {of as observableOf,  Observable } from 'rxjs';

import {map, catchError, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import * as Action from '../store/actions';
import { ChannelsService } from '../channels.service';
import { State } from '../../../app.reducer';
import mixpanel from 'mixpanel-browser/src/loader-module';
import {LoggingService} from "../../../shared/logging.service";

@Injectable()
export class Effects {

    /* Retrieve all channels from current user */

    @Effect()
    requestChannels: Observable<Action.RequestChannelsFailure | Action.RequestChannelsSuccess> = this.actions$
        .ofType(Action.REQUEST_CHANNELS).pipe(
        switchMap((action: Action.RequestChannels) => {
          return this.channelsService.getChannels().pipe(map((channels) => {
            return new Action.RequestChannelsSuccess(channels);
          }),
          catchError(e => {
            return observableOf(new Action.RequestChannelsFailure(e));
          }),);
        }));

    /* Update a channel */

    @Effect()
    updateChannel: Observable<Action.UpdateChannelFailure | Action.UpdateChannelSuccess> = this.actions$
        .ofType(Action.UPDATE_CHANNEL).pipe(
        switchMap((action: Action.UpdateChannel) => {
          return this.channelsService.updateChannels(action.payload).pipe(map((channels) => {
            this.log.Action("update-channel", {alert:action.payload})
            return new Action.UpdateChannelSuccess(action.payload);
          }),
          catchError(e => {
            return observableOf(new Action.UpdateChannelFailure(e));
          }),);
        }));


    /* Test a channel */

    @Effect()
    testChannel: Observable<Action.TestChannelFailure | Action.TestChannelSuccess> = this.actions$
        .ofType(Action.TEST_CHANNEL).pipe(
        switchMap((action: Action.TestChannel) => {
          return this.channelsService.testChannel(action.payload).pipe(map((channels) => {
            this.log.Action("test-channel", {alert:action.payload})
            return new Action.TestChannelSuccess(action.payload);
          }),
          catchError(e => {
            return observableOf(new Action.TestChannelFailure(e));
          }),);
        }));

    constructor(private channelsService: ChannelsService,
                private store$: Store<State>,
                private actions$: Actions,
                private log: LoggingService) {}
}

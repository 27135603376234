import {Component, OnInit, OnDestroy, Input, Inject, ViewChild, ElementRef} from '@angular/core';
import { v4 as uuidv4 } from "uuid";
import mixpanel from 'mixpanel-browser/src/loader-module';
import {IMqttMessage, MqttService} from "ngx-mqtt";
import {kerberosConfig} from "../../../../../../environments/environment";

@Component({
  selector: "Record",
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.scss'],
})
export class Record implements OnInit  {

  @Input() deviceKey: string;
  @Input() onvif: boolean = true;
  @Input() talk: boolean = true;
  public muted: boolean = true;
  public stream: Promise<void>;

  constructor(
    @Inject('mqttNew') private mqttService: MqttService) {}

  ngOnInit(){
    this.record = this.record.bind(this);
  }

  record(){
  }
}

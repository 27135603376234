import * as devices from './actions';
//import { User } from '../models/user';

export interface State {
  cameras: Array<any>;
  media: Array<any>;
  currentDevice: any;
  analytics: any;
  activity: any;
  version: any;
  devicesAdded: boolean;
  statistics: any;
  error: string;
  status: any;
  config: any;
  configUpdated: boolean
  configUpdating: boolean
}

export const initialState: State = {
  cameras: [],
  media: [],
  currentDevice: null,
  analytics: {},
  activity: [],
  version: null,
  devicesAdded: true,
  statistics: null,
  error: null,
  status: {
    addPresetSuccess: false,
    addPresetFailed: false,
    removePresetFailed: false,
    removePresetSuccess: false,
  },
  config: null,
  configUpdated: false,
  configUpdating: false,
};

export function reducer(state = initialState, action: devices.Actions): State {
  switch (action.type) {

    case devices.UPDATE_DEVICE_ACTIVITY: {
      return {
        ...state,
        cameras: state.cameras.map((camera, i) => camera.key === action.payload.key ? {...camera, active: action.payload.active} : camera)
      };
    }

    case devices.REMOVE_DEVICE: {
      let cameraIndex = state.cameras.findIndex(camera => camera.key === action.payload);
      let acitvityIndex = state.activity.findIndex(camera => camera.key === action.payload);
      return {
        ...state,
        cameras: [
          ...state.cameras.slice(0, cameraIndex),
          ...state.cameras.slice(cameraIndex + 1),
        ],
        activity: [
          ...state.activity.slice(0, acitvityIndex),
          ...state.activity.slice(acitvityIndex + 1),
        ]
      };
    }

    case devices.REQUEST_LATEST_VERSION_SUCCESS: {
      const version = action.payload;
      return {
        ...state,
        version
      };
    }

    case devices.REQUEST_DEVICES_SUCCESS: {
      const payload = action.payload;
      if(!payload) {
        return state;
      }

      // Get all cameras
      let cameras = state.cameras;
      const timeNow = Math.floor(Date.now() / 1000);
      payload.map((item) => {
        // Add or update camera's
        let foundCamera = cameras.find(camera => camera.key === item.key)
        const mostRecentInfo = item.analytics[item.analytics.length-1];
        if(mostRecentInfo) {
          if(foundCamera){
            foundCamera.name = mostRecentInfo.cameraname;
            foundCamera.sites = item.sites;
            foundCamera.color = item.color;
            foundCamera.description = item.description;
            foundCamera.uptime = mostRecentInfo.uptime || "";
            foundCamera.version = mostRecentInfo.version;
            foundCamera.enterprise = mostRecentInfo.enterprise;
            foundCamera.onvif = mostRecentInfo.onvif;
            foundCamera.onvif_zoom = mostRecentInfo.onvif_zoom;
            foundCamera.onvif_pantilt = mostRecentInfo.onvif_pantilt
            foundCamera.presets = item.presets;
            foundCamera.onvif_presets_list = mostRecentInfo.onvif_presets_list;
            foundCamera.connected = mostRecentInfo.cameraConnected === "" ? "true" : mostRecentInfo.cameraConnected,

            foundCamera.active = (timeNow - mostRecentInfo.timestamp) < 180;
            if (foundCamera.active === false) {
              foundCamera.uptime = "not online"
            }
            if(item.location) {
              const { lng, lat } = item.location.geometry.location;
              const location =  { lng, lat };
              foundCamera.location = location;
            }
          }
          else {
            let location = null;
            if(item.location) {
              const { lng, lat } = item.location.geometry.location;
              location = { lng, lat };
            }


            let uptime = mostRecentInfo.uptime && mostRecentInfo.uptime || "";
            const active = (timeNow - mostRecentInfo.timestamp) < 180;
            if (!active) {
              uptime = "not online"
            }

            cameras = [
              ...cameras,
              {
                key: item.key,
                color: item.color,
                description: item.description,
                sites: item.sites,
                name:  mostRecentInfo.cameraname,
                location,
                version:  mostRecentInfo.version,
                uptime,
                enterprise:  mostRecentInfo.enterprise,
                active,
                presets: item.presets,
                onvif_presets_list: mostRecentInfo.onvif_presets_list,
                onvif:  mostRecentInfo.onvif,
                onvif_zoom:  mostRecentInfo.onvif_zoom,
                onvif_pantilt:  mostRecentInfo.onvif_pantilt,
                ip_list:  mostRecentInfo.ip_list,
                mac_list:  mostRecentInfo.mac_list,
                release:  mostRecentInfo.release,
                connected:  mostRecentInfo.cameraConnected === "" ? "true" : mostRecentInfo.cameraConnected,
              }
            ]
          }
        }
      });

      let devicesAdded = true;
      if(!payload || payload.length == 0) {
        devicesAdded = false;
      }

      // Get all latest activity
      let activity = state.activity;
      payload.map((item, index) => {
        // Add or update camera's
        let foundCamera = activity.find(camera => camera.key === item.key)
        const mostRecentInfo = item.analytics[item.analytics.length-1];

        if(mostRecentInfo) {

          let uptime = mostRecentInfo.uptime && mostRecentInfo.uptime || "";
          const active = (timeNow - mostRecentInfo.timestamp) < 180;
          if (!active) {
            uptime = "not online"
          }

          if(foundCamera){
            foundCamera.name = mostRecentInfo.cameraname;
            if(
              (!foundCamera.activity && item.latestMedia) ||
              (foundCamera.activity && item.latestMedia && foundCamera.activity.key != item.latestMedia.key)
            ){
              activity = [
                ...activity.slice(0, index),
                {
                  key: item.key,
                  name: mostRecentInfo.cameraname,
                  connected: mostRecentInfo.cameraConnected === "" ? "true" : mostRecentInfo.cameraConnected,
                  uptime,
                  active,
                  activity: item.latestMedia,
                  presets: item.presets,
                  onvif_presets_list: mostRecentInfo.onvif_presets_list,
                },
                ...activity.slice(index + 1),
              ]
            }
          }
          else {
            activity = [
              ...activity,
              {
                key: item.key,
                name: mostRecentInfo.cameraname,
                connected: mostRecentInfo.cameraConnected === "" ? "true" : mostRecentInfo.cameraConnected,
                uptime,
                active,
                activity: item.latestMedia,
                presets: item.presets,
                onvif_presets_list: mostRecentInfo.onvif_presets_list,
              }
            ]
          }
        }
      });

      // Update analytics
      let analytics = {...state.analytics};

      for(let i = 0; i < payload.length; i++) {
        const cameraKey = payload[i].key;
        if (!analytics.hasOwnProperty(cameraKey)) {
          const analytic = payload[i].analytics
          const firstAnalytic = analytic[analytic.length-1]
          let uptime = firstAnalytic.uptime && firstAnalytic.uptime || "";
          const active = (timeNow - firstAnalytic.timestamp) < 180;
          if (!active) {
            uptime = "not online"
          }

          analytics[cameraKey] = [
            {
              ...firstAnalytic,
              uptime,
              active
            }
          ];
        }
        else {
          // Check if different, if so we'll append it.
          const lastAnalytic = analytics[cameraKey][analytics[cameraKey].length-1]
          const newAnalytic = payload[i].analytics[payload[i].analytics.length-1];

          if(lastAnalytic && newAnalytic && lastAnalytic.timestamp != newAnalytic.timestamp) {
            let uptime = newAnalytic.uptime && newAnalytic.uptime || "";
            const active = (timeNow - newAnalytic.timestamp) < 180;
            if (!active) {
              uptime = "not online"
            }
            analytics[cameraKey] = [
              {
                ...newAnalytic,
                uptime,
                active
              }
            ]
          }
        }
      }

      return {
        ...state,
        cameras,
        analytics,
        activity,
        devicesAdded,
        error: null
      };
    }

    case devices.REQUEST_MEDIA_DEVICES_SUCCESS: {
      const medias = action.payload;

      if(state.media.length == 0){
        return {
          ...state,
          media: medias,
        };
      }

      return {
        ...state,
        media: state.media.filter(x => medias.findIndex(y => y.key === x.key) > -1).map(x => {
          const media = medias.find(y => y.key === x.key);
          if(x.key === media.key && x.media_timestamp !== media.media_timestamp){
            return {
              ...x,
              ...media
            }
          }
          return x;
        })
      };
    }

    case devices.REQUEST_DEVICES_FAILURE: {
      const payload = action.payload;
      return {
        ...state,
        error: payload.error.data
      };
    }

    case devices.FIND_DEVICE_SUCCESS: {
      const device = action.payload;
      return {
        ...state,
        currentDevice: device
      };
    }

    case devices.MUTE_DEVICE_SUCCESS: {
      const { mute } = action.payload;
      return {
        ...state,
        currentDevice: {
          ...state.currentDevice,
          mute,
        }
      };
    }

    case devices.UPDATE_DEVICE_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        currentDevice: {
          ...state.currentDevice,
          ...payload
        }
      };
    }

    case devices.GET_DEVICES_STATISTICS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        statistics: payload
      };
    }

    case devices.UPDATE_DEVICE_PTZ_POSITION: {
      const payload = action.payload;
      const { position } = payload.value;
      return {
        ...state,
        currentDevice: {
          ...state.currentDevice,
          position,
        }
      };
    }

    case devices.ADD_PRESET: {
      return {
        ...state,
        status: {
          ...state.status,
          addPresetSuccess: false,
          addPresetFailed: false,
        }
      };
    }

    case devices.ADD_PRESET_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        status: {
          ...state.status,
          addPresetSuccess: true,
          addPresetFailed: false,
        },
        currentDevice: {
          ...state.currentDevice,
          ...payload
        }
      };
    }

    case devices.ADD_PRESET_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          addPresetSuccess: false,
          addPresetFailed: true,
        }
      };
    }

    case devices.REMOVE_PRESET_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        status: {
          ...state.status,
          removePresetSuccess: true,
          removePresetFailed: false,
        },
        currentDevice: {
          ...state.currentDevice,
          ...payload
        }
      }
    }

    case devices.REMOVE_PRESET_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          removePresetSuccess: false,
          removePresetFailed: true,
        }
      };
    }

    case devices.LOAD_CONFIG: {
      const payload = action.payload;
      return {
        ...state,
        config: payload
      };
    }

    case devices.UPDATE_CONFIG: {
      return {
        ...state,
        configUpdating: true,
        configUpdated: false
      };
    }

    case devices.UPDATE_CONFIG_SUCCESS: {
      return {
        ...state,
        configUpdating: false,
        configUpdated: true
      };
    }

    case devices.UPDATE_CONFIG_FAILURE:
    case devices.UPDATE_CONFIG_RESET: {
      return {
        ...state,
        configUpdating: false,
        configUpdated: false
      };
    }
    
    case devices.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getDevices = (state: State) => state;
export const getDevicesMedia = (state: State) => state.media;
export const getCurrentDevice = (state: State) => state.currentDevice;
export const getCameras = (state: State) => state.cameras;
export const getAnalytics = (state: State) => state.analytics;
export const getLatestVersion = (state: State) => state.version;
export const getLatestMedia = (state: State) => state.activity;
export const getStatistics = (state: State) => state.statistics;
export const getStatus = (state: State) => state.status;
export const getConfig = (state: State) => state.config;
export const getConfigUpdating = (state: State) => state.configUpdating;
export const getConfigUpdated = (state: State) => state.configUpdated;
export const getAnalyticsFromCamera = (state: State, key: string) => {
  return state[key];
}

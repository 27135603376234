import { Action } from '@ngrx/store';
import { User, Authenticate } from '../models/user';

export const LOGIN = '[Auth] Login';
export const LOGIN_SUCCESS = '[Auth] Login Success';
export const LOGIN_FAILURE = '[Auth] Login Failure';
export const SSO = '[Auth] SSO';
export const SSO_SUCCESS = '[Auth] SSO Success';
export const SSO_FAILURE = '[Auth] SSO Failure';
export const REGISTER = '[Auth] Registration';
export const REGISTER_SUCCESS = '[Auth] Registration Success';
export const REGISTER_FAILURE = '[Auth] Registration Failure';
export const ACTIVATE = '[Auth] Activate';
export const RESET_ACTIVATE = '[Auth] Reset Activate';
export const ACTIVATE_SUCCESS = '[Auth] Activate Success';
export const ACTIVATE_FAILURE = '[Auth] Activate Failure';
export const LOGIN_REDIRECT = '[Auth] Login Redirect';
export const LOGIN_CHECK = '[Auth] Check if logged in';
export const LOGOUT = '[Auth] Logout';
export const LOGOUT_SUCCESS = '[Auth] Logout Success';
export const FORGOT = '[Auth] Forgot';
export const FORGOT_SUCCESS = '[Auth] Forgot Success';
export const FORGOT_FAILURE = '[Auth] Forgot Failure';
export const RESET_MFA = '[Auth] Reset MFA';
export const REQUIRE_MFA = '[Auth] Require MFA';
export const RESET = '[Auth] Reset';

export class Login implements Action {
  readonly type = LOGIN;

  constructor(public payload: Authenticate) {}
}

export class LoginSuccess implements Action {
  readonly type = LOGIN_SUCCESS;

  constructor(public payload: any) {}
}

export class LoginFailure implements Action {
  readonly type = LOGIN_FAILURE;

  constructor(public payload?: any) {}
}

export class SingleSignOn implements Action {
  readonly type = SSO;

  constructor(public payload?: any) {}
}

export class SingleSignOnSuccess implements Action {
  readonly type = SSO_SUCCESS;

  constructor(public payload: any) {}
}

export class SingleSignOnFailure implements Action {
  readonly type = SSO_FAILURE;

  constructor(public payload?: any) {}
}

export class Register implements Action {
  readonly type = REGISTER;

  constructor(public payload) {}
}

export class RegisterSuccess implements Action {
  readonly type = REGISTER_SUCCESS;

  constructor(public payload: any) {}
}

export class RegisterFailure implements Action {
  readonly type = REGISTER_FAILURE;

  constructor(public payload?: any) {}
}

export class Activate implements Action {
  readonly type = ACTIVATE;

  constructor(public payload) {}
}

export class ResetActivate implements Action {
  readonly type = RESET_ACTIVATE;

  constructor() {}
}

export class ActivateSuccess implements Action {
  readonly type = ACTIVATE_SUCCESS;

  constructor(public payload: any) {}
}

export class ActivateFailure implements Action {
  readonly type = ACTIVATE_FAILURE;

  constructor(public payload?: any) {}
}

export class CheckIfLoggedIn implements Action {
  readonly type = LOGIN_CHECK;

  constructor(public payload?: any) {}
}

export class LoginRedirect implements Action {
  readonly type = LOGIN_REDIRECT;
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export class LogoutSuccess implements Action {
  readonly type = LOGOUT_SUCCESS;
}

export class Forgot implements Action {
  readonly type = FORGOT;

  constructor(public payload: any) {}
}

export class ForgotSuccess implements Action {
  readonly type = FORGOT_SUCCESS;

  constructor(public payload: any) {}
}

export class ForgotFailure implements Action {
  readonly type = FORGOT_FAILURE;

  constructor(public payload: any) {}
}

export class RequireMFA implements Action {
  readonly type = REQUIRE_MFA;

  constructor(public payload: any) {}
}

export class ResetMFA implements Action {
  readonly type = RESET_MFA;

  constructor() {}
}

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export type Actions =
  | Login
  | LoginSuccess
  | LoginFailure
  | SingleSignOn
  | SingleSignOnSuccess
  | SingleSignOnFailure
  | Register
  | RegisterSuccess
  | RegisterFailure
  | Activate
  | ResetActivate
  | ActivateSuccess
  | ActivateFailure
  | CheckIfLoggedIn
  | LoginRedirect
  | Logout
  | LogoutSuccess
  | Forgot
  | ForgotSuccess
  | ForgotFailure
  | RequireMFA
  | ResetMFA
  | Reset;

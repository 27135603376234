import { Injectable } from '@angular/core';
import {mixpanelConfig, posthogConfig} from '../../environments/environment';
import posthog from "posthog-js";
import mixpanel from 'mixpanel-browser/src/loader-module';

@Injectable()
export class LoggingService {

  private posthogApiKey : string;
  private posthogUrl : string;

  public actions = {
    // Page visits
    "visit-dashboard": "visitDashboard",
    "visit-latest-events": "visitLatestEvents",
    "visit-livestream": "visitLivestream",
    "visit-media": "visitMedia",
    "visit-media-overview": "visitMediaOverview", // This is opening a single sequence.
    "visit-media-detail": "visitMediaDetail", // This is opening a single recording.
    "visit-tasks": "visitTasks",
    "visit-alerts": "visitAlerts",
    "visit-channels": "visitChannels",
    "visit-cameras": "visitCameras",
    "visit-sites": "visitSites",
    "visit-sites-detail": "visitSitesDetail",

    // Actions
    "mute-speech": "muteSpeech", // On the livestreaming page someone can toggle (on/off) the voice speech if motion was detected.
    "filter-media": "filterMedia", // This is when someone is using the filter on the media page
    "update-task-status": "updateTaskStatus", // A task can be open, rejected or approved.
    "delete-task": "deleteTask", // A task is deleted, and we got it's ID.
    "search-task": "searchTask", // Search a task in the task list.
    "update-alert": "updateAlert", // Changing the settings of an alert (sms, pushover, etc.)
    "search-alert": "searchAlert", // Searching for an alert.
    "send-alert-suggestion": "sendAlertSuggestion", // Proposing a new alert, which isn't available yet.
    "update-channel": "updateChannel", // Changing the settings of a channel (detection, camera, etc.)
    "test-channel": "testChannel", // Will trigger the channel, with the defined settings.
    "search-channel": "searchChannel", // Searching for an channel.
    "information-channel": "informationChannel", // Request information how to setup a channel.
    "send-channel-suggestion": "sendChannelSuggestion", // Proposing a new channel, which isn't available yet.
    "add-device": "addDevice", // This is opening the add new device moda
    "switch-device-view": "switchDeviceView", // Switch the layout of the devices page (list, grid, ..)
    "add-site": "addSite", // Adding a new site
    "edit-site": "editSite", // Updating an existing site
    "remove-site": "removeSite", // Removing a site from the sites list
    "search-site": "searchSite", // Looking for a site
    "move-site-map": "moveSiteMap", // Moving the site map around to find sites and filter them.
  }

  constructor() {
    this.posthogApiKey = posthogConfig.key; // "zhjfr1tyn9IYdB9oM4mtkbICYtsMZWkAHw8aDmUZpFk";
    this.posthogUrl = posthogConfig.url; // 'https://posthog.kerberos.io'
    if(this.posthogApiKey != "") {
      posthog.init(this.posthogApiKey, {
        api_host: this.posthogUrl,
      })
    }
  }

  public Info(page) {
    if(this.actions && this.actions[page]) {
      const p = this.actions[page];
      if(this.posthogApiKey != "") {
        posthog.capture(p);
      }
    }
  }

  public Action(action, payload){
    if(this.actions && this.actions[action]) {
      const a = this.actions[action];
      if(this.posthogApiKey != "") {
        posthog.capture(a, payload);
      }
    }
  }

  public Error() {
    // @TODO
  }

  public Warning() {
    // @TODO
  }

  public Identify(username: string, email: string, provider: string) {
    if(this.posthogApiKey != "") {
      posthog.identify(
        username, // distinct_id, required
        {
          username,
          email,
          provider,
        }, // $set, optional
      );
    }
  }
}

import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as fromProfile from './profile';

export * from './effects';

export interface ProfileState {
  profile: fromProfile.State;
}

export interface State extends fromRoot.State {
  profile: ProfileState;
}
export const Reducers = {
  profile: fromProfile.reducer
};

export const selectProfileState = createFeatureSelector<ProfileState>('profile');
export const selectProfileProfileState = createSelector(selectProfileState, (state: ProfileState) => state.profile);
export const getInvoices = createSelector(selectProfileProfileState, fromProfile.getInvoices);
export const getProfile = createSelector(selectProfileProfileState, fromProfile.getProfile);
export const getShowCardForm = createSelector(selectProfileProfileState, fromProfile.getShowCardForm);
export const getQRCode = createSelector(selectProfileProfileState, fromProfile.getQRCode);
export const getQRCodeError = createSelector(selectProfileProfileState, fromProfile.getQRCodeError);
export const getSecret = createSelector(selectProfileProfileState, fromProfile.getSecret);
export const getMFA = createSelector(selectProfileProfileState, fromProfile.getMFA);

import {Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatTableDataSource} from "@angular/material";

@Component({
  selector: "TableField",
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', padding: '0'})),
      state('expanded', style({ height: 'auto', padding: '40px 0 40px 0' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TableField implements OnInit, OnDestroy, OnChanges  {

  @Input("action") action: any;
  @Input("columns") columns: any = [];
  @Input("data") data: any = [];
  @Input("expandable") expandable: boolean = false;

  public columnHeadings = [];
  public columnFields = [];

  public dataSource = new MatTableDataSource<any>();

  public graphAllElements: any[];
  public graph: any[] = [];
  public graphTotalElements = 0;
  public showXAxis = false;
  public showYAxis = false;
  public gradient = false;
  public showLegend = false;
  public showLabels = false;
  public showXAxisLabel = false;
  public showYAxisLabel = false;
  public tooltipDisabled = true;
  public colorScheme = {
    domain: ['#EDEBEB']
  };
  public autoScale = true;

  constructor() {

    const instance = {
      name: "colorScheme",
      color: this.colorScheme.domain[0],
      series: []
    };

    for(let i = 0; i < 10; i++) {
      instance.series.push({
        "name": i,
        "value": Math.floor(Math.random() * Math.floor(50)),
      });
    }

    this.graph.push(instance);
  }

  ngOnInit() {
    this.columnHeadings = this.columns.map(c => c.column);
    this.columnFields = this.columns.map(c => c.field);
    this.dataSource.data = this.data;

    if(this.expandable) {
      this.columnFields.push("expanded");
    }
    /*this.dataSource.filterPredicate = (data: any, filter: string) => {
      return data.name.indexOf(filter) != -1;
    }*/
  }

  ngAfterViewInit(){
  }

  ngOnDestroy(){
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dataSource.data = changes.data.currentValue;
  }

}

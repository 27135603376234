import {Component, OnInit, OnDestroy, Input, Inject, ViewChild, ElementRef} from '@angular/core';
import { v4 as uuidv4 } from "uuid";
import mixpanel from 'mixpanel-browser/src/loader-module';
import {IMqttMessage, MqttService} from "ngx-mqtt";
import {kerberosConfig} from "../../../../../../environments/environment";

@Component({
  selector: "PTZ",
  templateUrl: './ptz.component.html',
  styleUrls: ['./ptz.component.scss'],
})
export class PTZ implements OnInit, OnDestroy  {

  @Input() deviceKey: string;
  @Input() onvif: boolean = false;
  @Input() zoom: boolean = false;
  @Input() pantilt: boolean = false;

  constructor(
    @Inject('mqttNew') private mqttService: MqttService) {}

  ngOnInit(){
    this.changeDirection = this.changeDirection.bind(this);
    this.changeZoom = this.changeZoom.bind(this);
  }

  ngAfterViewInit(){
  }

  changeDirection(value){
    let up = 0;
    let down = 0;
    let right = 0;
    let left = 0;
    let center = 0;

    switch (value){
      case "up":
        up = 1;
        break;
      case "down":
        down = 1;
        break;
      case "left":
        left = 1;
        break;
      case "right":
        right = 1;
        break;
      case "center":
        center = 1;
        break;
    }
    const pantilt = {
      action: "ptz",
      payload: {
        up,
        down,
        left,
        right,
        center
      }
    };
    this.mqttService.unsafePublish(`kerberos/onvif/${this.deviceKey}`, JSON.stringify(pantilt), {qos: 0});
  }

  changeZoom(value){
    const zoom = {
      action: "zoom",
      payload: {
        zoom: value
      }
    };
    this.mqttService.unsafePublish(`kerberos/onvif/${this.deviceKey}`, JSON.stringify(zoom), {qos: 0});
  }

  ngOnDestroy() {
  }
}


import {defer as observableDefer, of as observableOf,  Observable } from 'rxjs';
import {take, catchError, map, switchMap, delay, exhaustMap, withLatestFrom, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { Authenticate } from '../models/user';
import * as Action from '../store/actions';
import { LoginService } from '../login.service';
import { State } from '../../app.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {LoggingService} from "../../shared/logging.service";

@Injectable()
export class Effects {

  public isDemo: boolean = environment.demo;

  /* Trying to login the user */

  @Effect()
  login: Observable<Action.LoginFailure | Action.LoginSuccess | Action.RequireMFA> = this.actions$
    .ofType(Action.LOGIN).pipe(
      delay(new Date(Date.now() + 100)),
      exhaustMap((action: Action.Login) => {
        let request : Observable<any>;

        if ((action.payload as Authenticate).username) {
          request = this.loginService.login(action.payload);
        }

        //if (!this.isDemo &&
        //    action.payload.username === 'demo') {
        //  return observableOf(new Action.LoginFailure("demo_account"))
        //}

        return request.pipe(switchMap(payload => { // Retrieve user from Kerberos API.
            return (payload && payload.token) ? this.loginService.withAPI(payload.token) : observableOf(false);
          }),
          map(profile => {
            if(profile) {

              this.log.Identify(action.payload.username, profile.email, action.payload.provider)

              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('currentUser', JSON.stringify(profile));
              return new Action.LoginSuccess(profile);
            }
            return new Action.RequireMFA(action.payload);
          }),
          catchError(error => {
            //mixpanel.track("loginUserFailed", {username: action.payload.username, error: error});
            return observableOf(new Action.LoginFailure(error.error.error))
          }),)
      }),);

  /* Received a JWT token (generated from the SSO token) */

  @Effect()
  sso: Observable<Action.SingleSignOnFailure | Action.SingleSignOnSuccess> = this.actions$
    .ofType(Action.SSO).pipe(
      switchMap((action: Action.SingleSignOn) => {
        const token = action.payload;
        return this.loginService.withAPI(token).pipe(map((profile) => {
            if(profile) {

              // Send to logging service.
              this.log.Identify(action.payload.username, profile.email, action.payload.provider)

              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('currentUser', JSON.stringify(profile));
              return new Action.SingleSignOnSuccess(profile);
            }
          }),
          catchError((error: HttpErrorResponse) => {
            return observableOf(new Action.SingleSignOnFailure(error.error.error))
          }),);
      }),);

  @Effect({ dispatch: false })
  ssoSuccess = this.actions$
    .ofType(Action.SSO_SUCCESS).pipe(
      withLatestFrom(this.store$),
      tap(([action, state]) => { // Get route from store.
        const path = window.location.href;
        var parser = document.createElement('a');
        parser.href = path;
        window.location.href = parser.protocol + "//" + parser.host
      }),);

  /* Trying to register the user */

  @Effect()
  register: Observable<Action.RegisterFailure | Action.RegisterSuccess> = this.actions$
    .ofType(Action.REGISTER).pipe(
    switchMap((action: Action.Register) => {
      return this.loginService.register(action.payload).pipe(map((subscription) => {
        //mixpanel.track("registerUser", {username: data.username, email: data.email});
        return new Action.RegisterSuccess({success: true});
      }),
      catchError((e: HttpErrorResponse) => {
        const data = action.payload;
        //mixpanel.track("registerUserFailed", {username: data.username, email: data.email, error: e});
        return observableOf(new Action.RegisterFailure(e.error.message));
      }),);
    }));

  /* Send a forgot password */

  @Effect()
  forgot: Observable<Action.ForgotFailure | Action.ForgotSuccess> = this.actions$
    .ofType(Action.FORGOT).pipe(
    switchMap((action: Action.Forgot) => {
      const data = action.payload;
      return this.loginService.forgot({
        usernameOrEmail: data.usernameOrEmail,
        domain: data.domain,
      }).pipe(map((forgot) => {
        //mixpanel.track("forgotPassword", {usernameOrEmail: data.usernameOrEmail});
        return new Action.ForgotSuccess({success: true});
      }),
      catchError((e: HttpErrorResponse) => {
        const data = action.payload;
        //mixpanel.track("forgotPasswordFailed", {usernameOrEmail: data.usernameOrEmail, error: e});
        return observableOf(new Action.ForgotFailure(e.error.message));
      }),);
    }));

  /* Trying to activating the user */

  @Effect()
  activate: Observable<Action.ActivateFailure | Action.ActivateSuccess> = this.actions$
    .ofType(Action.ACTIVATE).pipe(
    delay(new Date(Date.now() + 1000)),
    switchMap((action: Action.Register) => {
      return this.loginService.activate(action.payload).pipe(map((data) => {
        //mixpanel.track("activateUser", {token: action.payload});
        return new Action.ActivateSuccess({success: true});
      }),
      catchError((e: HttpErrorResponse) => {
        //mixpanel.track("activateUserFailed", {token: action.payload, error: e});
        return observableOf(new Action.ActivateFailure(e.error.message));
      }),);
    }),);

  /* Check if user is logged in */

  @Effect()
  checkIfLoggedIn: Observable<Action.LoginFailure | Action.LoginSuccess> = this.actions$
    .ofType(Action.LOGIN_CHECK).pipe(
    map((action: Action.CheckIfLoggedIn) => action.payload),
    switchMap(payload => {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      return (currentUser && currentUser.accesstoken) ? this.loginService.withAPI(currentUser.accesstoken) : observableOf(false);
    }),
    take(1),
    map(profile => {
        if (profile) {
          /// User logged in
          return new Action.LoginSuccess(profile);
        } else {
          /// User not logged in, do nothing..
          return new Action.LoginFailure(null);
        }
    }),
    catchError(error =>  observableOf(new Action.LoginFailure(error.error.error)) ),);


  @Effect({ dispatch: false })
  loginSuccess = this.actions$
    .ofType(Action.LOGIN_SUCCESS).pipe(
    withLatestFrom(this.store$),
    tap(([action, state]) => { // Get route from store.
      const currentUrl = window.location.href;
      const findSuffix = currentUrl.search(/login/i);
      const numberOfElements = currentUrl.split("/").length;
      if(findSuffix > -1){
        this.router.navigate(['/']);
      } else if(numberOfElements === 4 && currentUrl.substr(currentUrl.length-1) === "/") {
        this.router.navigate(['/']);
      }
    }),);

  @Effect({ dispatch: false })
  loginFailed = this.actions$
    .ofType(Action.LOGIN_FAILURE).pipe(
    withLatestFrom(this.store$),
    tap(([action, state]) => { // Get route from store.
      this.router.navigate(['/login']);
    }),);

  /* Requesting to logout the current user */

  @Effect()
  logout: Observable<Action.LogoutSuccess> = this.actions$
      .ofType(Action.LOGOUT).pipe(
      map(() => {
        this.loginService.logout();
        return new Action.LogoutSuccess()
      }));

  @Effect({ dispatch: false })
  logoutSuccess = this.actions$
    .ofType(Action.LOGOUT_SUCCESS).pipe(
    tap(() => {
      //mixpanel.track("logoutUser");
      localStorage.removeItem('currentUser');
      this.router.navigate(['/login']);
    }));

  /*  Auto start CheckIfLoggedIn action on init */

  @Effect()
  init$: Observable<Action.CheckIfLoggedIn> = observableDefer(() => {
    // this is a hack for knowing the url before the store is loaded.
    // the idea is that for specific urls there is no checkloggedin required.
    const currentUrl = window.location.href;
    if(!currentUrl.includes('/login/activate') && !currentUrl.includes('/login/sso')) {
      return observableOf(new Action.CheckIfLoggedIn());
    }
  });

  constructor(private loginService: LoginService,
              private store$: Store<State>,
              private actions$: Actions,
              private router: Router,
              private log: LoggingService) {}
}

import { Action } from '@ngrx/store';

export const RESET = '[Profile] Reset';
export const REQUEST_PROFILE = '[Profile] Requesting profile';
export const REQUEST_PROFILE_SUCCESS = '[Profile] Requesting profile Success';
export const REQUEST_PROFILE_FAILURE = '[Profile] Requesting profile Failure';
export const UPDATE_PROFILE = '[Profile] Update profile';
export const UPDATE_PROFILE_SUCCESS = '[Profile] Update profile Success';
export const UPDATE_PROFILE_FAILURE = '[Profile] Update profile Failure';
export const UPDATE_SETTINGS = '[Profile] Update settings';
export const UPDATE_SETTINGS_SUCCESS = '[Profile] Update settings Success';
export const UPDATE_SETTINGS_FAILURE = '[Profile] Update settings Failure';
export const REQUEST_INVOICES = '[Profile] Requesting invoices';
export const REQUEST_INVOICES_SUCCESS = '[Profile] Requesting invoices Success';
export const REQUEST_INVOICES_FAILURE = '[Profile] Requesting invoices Failure';
export const UPDATE_CREDENTIALS = '[Profile] Update credentials';
export const UPDATE_CREDENTIALS_SUCCESS = '[Profile] Update credentials Success';
export const UPDATE_CREDENTIALS_FAILURE = '[Profile] Update credentials Failure';
export const UPDATE_CARD = '[Profile] Update card';
export const UPDATE_CARD_SUCCESS = '[Profile] Update card Success';
export const UPDATE_CARD_FAILURE = '[Profile] Update card Failure';
export const DELETE_CARD = '[Profile] Delete card';
export const DELETE_CARD_SUCCESS = '[Profile] Delete card Success';
export const DELETE_CARD_FAILURE = '[Profile] Delete card Failure';
export const GET_QRCODE = '[Profile] Get QR code';
export const GET_QRCODE_SUCCESS = '[Profile] Get QR code Success';
export const GET_QRCODE_FAILURE = '[Profile] Get QR code Failure';
export const VALIDATE_QRCODE = '[Profile] Validate QR code';
export const VALIDATE_QRCODE_SUCCESS = '[Profile] Validate QR code Success';
export const VALIDATE_QRCODE_FAILURE = '[Profile] Validate QR code Failure';
export const DISABLE_MFA = '[Profile] Disable MFA';
export const DISABLE_MFA_SUCCESS = '[Profile] Disable MFA Success';
export const DISABLE_MFA_FAILURE = '[Profile] Disable MFA Failure';
export const SHOW_CARD_FROM = '[Profile] Show card form';
export const CLOSE_CARD_FROM = '[Profile] Close card form';

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export class RequestProfile implements Action {
  readonly type = REQUEST_PROFILE;

  constructor() {}
}

export class RequestProfileSuccess implements Action {
  readonly type = REQUEST_PROFILE_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestProfileFailure implements Action {
  readonly type = REQUEST_PROFILE_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateProfile implements Action {
  readonly type = UPDATE_PROFILE;

  constructor(public payload: any) {}
}

export class UpdateProfileSuccess implements Action {
  readonly type = UPDATE_PROFILE_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateProfileFailure implements Action {
  readonly type = UPDATE_PROFILE_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateSettings implements Action {
  readonly type = UPDATE_SETTINGS;

  constructor(public payload: any) {}
}

export class UpdateSettingsSuccess implements Action {
  readonly type = UPDATE_SETTINGS_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateSettingsFailure implements Action {
  readonly type = UPDATE_SETTINGS_FAILURE;

  constructor(public payload?: any) {}
}

export class RequestInvoices implements Action {
  readonly type = REQUEST_INVOICES;

  constructor() {}
}

export class RequestInvoicesSuccess implements Action {
  readonly type = REQUEST_INVOICES_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestInvoicesFailure implements Action {
  readonly type = REQUEST_INVOICES_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateCredentials implements Action {
  readonly type = UPDATE_CREDENTIALS;

  constructor(public payload: any) {}
}

export class UpdateCredentialsSuccess implements Action {
  readonly type = UPDATE_CREDENTIALS_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateCredentialsFailure implements Action {
  readonly type = UPDATE_CREDENTIALS_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateCard implements Action {
  readonly type = UPDATE_CARD;

  constructor(public payload: any) {}
}

export class UpdateCardSuccess implements Action {
  readonly type = UPDATE_CARD_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateCardFailure implements Action {
  readonly type = UPDATE_CARD_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteCard implements Action {
  readonly type = DELETE_CARD;

  constructor() {}
}

export class DeleteCardSuccess implements Action {
  readonly type = DELETE_CARD_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteCardFailure implements Action {
  readonly type = DELETE_CARD_FAILURE;

  constructor(public payload?: any) {}
}

export class ShowCardForm implements Action {
  readonly type = SHOW_CARD_FROM;

  constructor() {}
}

export class CloseCardForm implements Action {
  readonly type = CLOSE_CARD_FROM;

  constructor() {}
}

export class GetQRCode implements Action {
  readonly type = GET_QRCODE;

  constructor() {}
}

export class GetQRCodeSuccess implements Action {
  readonly type = GET_QRCODE_SUCCESS;

  constructor(public payload: any) {}
}

export class GetQRCodeFailure implements Action {
  readonly type = GET_QRCODE_FAILURE;

  constructor(public payload?: any) {}
}

export class ValidateQRCode implements Action {
  readonly type = VALIDATE_QRCODE;

  constructor(public payload: any) {}
}

export class ValidateQRCodeSuccess implements Action {
  readonly type = VALIDATE_QRCODE_SUCCESS;

  constructor(public payload: any) {}
}

export class ValidateQRCodeFailure implements Action {
  readonly type = VALIDATE_QRCODE_FAILURE;

  constructor(public payload?: any) {}
}

export class DisableMFA implements Action {
  readonly type = DISABLE_MFA;

  constructor() {}
}

export class DisableMFASuccess implements Action {
  readonly type = DISABLE_MFA_SUCCESS;

  constructor(public payload: any) {}
}

export class DisableMFAFailure implements Action {
  readonly type = DISABLE_MFA_FAILURE;

  constructor(public payload?: any) {}
}

export type Actions =
  | Reset
  | RequestProfile
  | RequestProfileSuccess
  | RequestProfileFailure
  | UpdateProfile
  | UpdateProfileSuccess
  | UpdateProfileFailure
  | UpdateSettings
  | UpdateSettingsSuccess
  | UpdateSettingsFailure
  | RequestInvoices
  | RequestInvoicesSuccess
  | RequestInvoicesFailure
  | UpdateCredentials
  | UpdateCredentialsSuccess
  | UpdateCredentialsFailure
  | UpdateCard
  | UpdateCardSuccess
  | UpdateCardFailure
  | DeleteCard
  | DeleteCardSuccess
  | DeleteCardFailure
  | ShowCardForm
  | CloseCardForm
  | GetQRCode
  | GetQRCodeSuccess
  | GetQRCodeFailure
  | ValidateQRCode
  | ValidateQRCodeSuccess
  | ValidateQRCodeFailure
  | DisableMFA
  | DisableMFASuccess
  | DisableMFAFailure;

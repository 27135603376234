
import {of as observableOf,  Observable } from 'rxjs';

import {map, switchMap, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import * as Action from '../store/actions';
import { LivestreamService } from '../livestream.service';
import { State } from '../../../app.reducer';
import mixpanel from 'mixpanel-browser/src/loader-module';

@Injectable()
export class Effects {

    /* Retrieve livestream properties */

    @Effect()
    RequestLivestreamProperties: Observable<Action.RequestLivestreamFailure | Action.RequestLivestreamSuccess> = this.actions$
        .ofType(Action.REQUEST_LIVESTREAM).pipe(
        switchMap((action: Action.RequestLivestream) => {
          return this.livestreamService.getLivestream().pipe(map((livestream) => {
            return new Action.RequestLivestreamSuccess(livestream.data);
          }),
          catchError(e => {
            return observableOf(new Action.RequestLivestreamFailure(e));
          }),);
        }));

    /* Toggle speech */

    @Effect()
    ToggleSpeech: Observable<Action.ToggleSpeechFailure | Action.ToggleSpeechSuccess> = this.actions$
        .ofType(Action.TOGGLE_SPEECH).pipe(
        switchMap((action: Action.ToggleSpeech) => {
          return this.livestreamService.updateSpeech(action.payload).pipe(map((livestream) => {
            const speech = livestream.data.speech;
            return new Action.ToggleSpeechSuccess(speech);
          }),
          catchError(e => {
            return observableOf(new Action.ToggleSpeechFailure(e));
          }),);
        }));


    constructor(private livestreamService: LivestreamService,
                private store$: Store<State>,
                private actions$: Actions,
                private router: Router,
                private route: ActivatedRoute) {}
}

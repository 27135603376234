export enum Levels {
    NoPlan = 0,
    Basic = 1 ,
    Premium = 2,
    Gold = 3,
    Business = 4,
    Enterprise = 5,
    Corporate = 5,
    global = 5,
    Demo = 5,
    Unlimited = 5
}

export const Pricing = {
  'basic': '€1,99',
  'premium': '€4,99',
  'gold': '€9,99',
  'business': '€29,99',
  'enterprise': '€49,99',
  'corporate': '€349,99',
  'global': '€999,99'
};
  
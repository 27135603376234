import { Inject, Injectable } from '@angular/core';
import { MqttService as NgxMqtt } from 'ngx-mqtt';
import { v4 as uuidv4 } from "uuid";

@Injectable()
export class MqttService {
  
  constructor(@Inject('mqttNew') private mqttService: NgxMqtt,
    @Inject('mqttLegacy') private mqttLegacyService: NgxMqtt) {}

  public subscribe(topic, callback) {
    // We might need to decrypt using a key before it's able to be read.
    return this.mqttService.observe(topic).subscribe((message) => {
      const msg = JSON.parse(message.payload.toString());
      callback(msg)
    });
  }

  public publish(topic, payload) {
    // We'll add some metadata first.
    const message = {
      mid: uuidv4(),
      timestamp: Math.floor(Date.now() / 1000),
      encrypted: false,
      public_key: "",
      fingerprint: "",
      payload
    }

    // We might need to encrypt using a key before it's send over..
    if(payload.encrypted){
      // Encrypt payload
      // Generate fingerprint to autheticate the request
      // update the payload
      message.payload = payload;
    }
    
    this.mqttService.unsafePublish(topic, JSON.stringify(message), { qos: 1, retain: false });
  }
}

import {Component, OnInit, OnDestroy, Input, Inject, ViewChild, ElementRef} from '@angular/core';
import { v4 as uuidv4 } from "uuid";
import mixpanel from 'mixpanel-browser/src/loader-module';
import {IMqttMessage, MqttService} from "ngx-mqtt";
import {kerberosConfig} from "../../../../../../environments/environment";

@Component({
  selector: "Talk",
  templateUrl: './talk.component.html',
  styleUrls: ['./talk.component.scss'],
})
export class Talk implements OnInit, OnDestroy  {

  @Input() deviceKey: string;
  @Input() onvif: boolean = true;
  @Input() talk: boolean = true;
  public muted: boolean = true;
  public stream: Promise<void>;

  constructor(
    @Inject('mqttNew') private mqttService: MqttService) {}

  ngOnInit(){
    this.sendAudio = this.sendAudio.bind(this);
  }

  sendAudio(){
    this.muted = !this.muted;
    //this.mqttService.unsafePublish(`kerberos/onvif/${this.deviceKey}`, JSON.stringify(pantilt), {qos: 0});
    if(!this.muted){
      this.stream = navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((s) => {
        
      })
      .catch((err) => {
        console.error(`you got an error: ${err}`);
      });
    } else {
      // Stop streaming audio
      // ...
    }
  }

  ngOnDestroy() {
  }
}

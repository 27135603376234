import {Component, OnInit, OnDestroy, Input, OnChanges, SimpleChange, SimpleChanges} from '@angular/core';
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {FormControl} from "@angular/forms";

@Component({
  selector: "DatePicker",
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatePicker implements OnInit, OnChanges  {

  @Input("icon") icon: string;
  @Input("text") text: string;
  @Input("currentDate") currentDate: any;
  @Input("onChange") onChange: any;
  @Input("minDate") minDate: any;
  @Input("maxDate") maxDate: any;
  public date: FormControl;

  constructor() {
    this.addEvent = this.addEvent.bind(this);
    this.date = new FormControl();
  }

  ngOnInit(){
    this.date.setValue(this.currentDate);
  }

  ngOnChanges(change: SimpleChanges){
    if(change.currentDate) {
      this.date.setValue(change.currentDate.currentValue);
    }
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.onChange(event.value);
  }
}


import {of as observableOf,  Observable } from 'rxjs';
import {map, catchError, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import * as Action from '../store/actions';
import { LocationsService } from '../locations.service';
import { State } from '../../../app.reducer';
import mixpanel from 'mixpanel-browser/src/loader-module';

@Injectable()
export class Effects {

    constructor(private locationsService: LocationsService,
                private store$: Store<State>,
                private actions$: Actions,
                private router: Router,
                private route: ActivatedRoute) {}
}

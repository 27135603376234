import {of as observableOf,  Observable } from 'rxjs';
import {catchError, switchMap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import * as Action from '../store/actions';
import { RolesService } from '../roles.service';
import { State } from '../../../app.reducer';

@Injectable()
export class Effects {

    /* Retrieve all roles */

    @Effect()
    requestRoles: Observable<Action.RequestRolesFailure | Action.RequestRolesSuccess> = this.actions$
        .ofType(Action.REQUEST_ROLES).pipe(
        switchMap((action: Action.RequestRoles) => {
          return this.rolesService.getRoles().pipe(map((roles) => {
            return new Action.RequestRolesSuccess(roles);
          }),
          catchError(e => {
            return observableOf(new Action.RequestRolesFailure(e));
          }),);
        }));

    /* Add role */

    @Effect()
    addRole: Observable<Action.AddRoleFailure | Action.AddRoleSuccess> = this.actions$
        .ofType(Action.ADD_ROLE).pipe(
        switchMap((action: Action.AddRole) => {
          return this.rolesService.addRole(action.payload).pipe(map((roles) => {
            return new Action.AddRoleSuccess(roles);
          }),
          catchError(e => {
            return observableOf(new Action.AddRoleFailure(e.error.message));
          }),);
        }));

    /* Edit role */

    @Effect()
    editRole: Observable<Action.EditRoleFailure | Action.EditRoleSuccess> = this.actions$
        .ofType(Action.EDIT_ROLE).pipe(
        switchMap((action: Action.EditRole) => {
          return this.rolesService.editRole(action.id, action.payload).pipe(map((roles) => {
            return new Action.EditRoleSuccess(roles);
          }),
          catchError(e => {
            return observableOf(new Action.EditRoleFailure(e.error.message));
          }),);
        }));

    /* Delete role */

    @Effect()
    deleteRole: Observable<Action.DeleteRoleFailure | Action.DeleteRoleSuccess> = this.actions$
        .ofType(Action.DELETE_ROLE).pipe(
        switchMap((action: Action.DeleteRole) => {
          return this.rolesService.deleteRole(action.id).pipe(map((roles) => {
            return new Action.DeleteRoleSuccess(roles);
          }),
          catchError(e => {
            return observableOf(new Action.DeleteRoleFailure(e));
          }),);
        }));

    constructor(private rolesService: RolesService,
                private store$: Store<State>,
                private actions$: Actions,
                private router: Router,
                private route: ActivatedRoute) {}
}

import * as profile from './actions';
//import { User } from '../models/user';

export interface State {
  invoices: Array<any>;
  user: any;
  profileCompleted: boolean;
  card: any;
  updating: boolean;
  updatingProfile: boolean;
  updatingMessage: string;
  updatingCredentials: boolean;
  updatingCard: boolean;
  error: boolean;
  errormessage: string;
  errorcard: boolean;
  errorcardmessage: string;
  errorpassword: boolean;
  success: boolean;
  successmessage: string;
  successpassword: boolean;
  showCardForm: boolean;
  qrcode: any;
  qrcodeerror: boolean;
  mfasecret: string;
}

export const initialState: State = {
  invoices: [],
  user: null,
  profileCompleted: true,
  card: null,
  updating: false,
  updatingProfile: false,
  updatingMessage: null,
  updatingCredentials: false,
  updatingCard: false,
  error: false,
  errormessage: "",
  errorcard:false,
  errorcardmessage:"",
  errorpassword:false,
  success: false,
  successmessage: "",
  successpassword: false,
  showCardForm: false,
  qrcode: null,
  qrcodeerror: false,
  mfasecret: "",
};

export function reducer(state = initialState, action: profile.Actions): State {
  switch (action.type) {

    case profile.REQUEST_PROFILE_SUCCESS: {
      const payload = action.payload;

      let profileCompleted = true;
      if(payload.user && payload.user.profileCompleted !== null) {
        profileCompleted = payload.user.profileCompleted;
      }

      return {
        ...state,
        user: payload.user,
        card: payload.card,
        profileCompleted
      }
    }

    case profile.REQUEST_PROFILE_FAILURE: {
      // This will force a reload, it can be that the user login expired.
      location.reload();
      return state
    }

    case profile.REQUEST_INVOICES_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        invoices: payload.data
      }
    }

    case profile.UPDATE_CARD: {
      const payload = action.payload;
      return {
        ...state,
        updating: true,
        updatingCard: true,
        errorcard: false,
        errorcardmessage: "",
        success: false,
        successmessage: "",
        updatingMessage: "Attaching payment source"
      }
    }

    case profile.UPDATE_CARD_FAILURE: {
      const payload = action.payload;
      return {
        ...state,
        updating: false,
        updatingCard: false,
        errorcard: true,
        errorcardmessage: payload
      }
    }

    case profile.UPDATE_CARD_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        updating: false,
        updatingCard: false,
        showCardForm: false,
        card: payload.card,
        success: true,
        successmessage: payload.success
      }
    }

    case profile.UPDATE_CREDENTIALS: {
      const payload = action.payload;
      return {
        ...state,
        updatingCredentials: true,
        errorpassword: false,
        successpassword: false,
      }
    }

    case profile.UPDATE_CREDENTIALS_FAILURE: {
      const payload = action.payload;
      return {
        ...state,
        updatingCredentials: false,
        errorpassword: true,
        successpassword: false,
      }
    }

    case profile.UPDATE_CREDENTIALS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        updatingCredentials: false,
        errorpassword: false,
        successpassword: true,
      }
    }

    case profile.UPDATE_PROFILE: {
      const payload = action.payload;
      return {
        ...state,
        updatingProfile: true,
        error: false,
        errormessage: "",
        success: false,
        successmessage: "",
      }
    }

    case profile.UPDATE_PROFILE_FAILURE: {
      const payload = action.payload;
      return {
        ...state,
        updatingProfile: false,
        error: true,
        errormessage: payload.data
      }
    }

    case profile.UPDATE_PROFILE_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        updatingProfile: false,
        user: {
          ...state.user,
          ...payload.user
        },
        profileCompleted: true,
        success: true,
        successmessage: payload.data
      }
    }



    case profile.UPDATE_SETTINGS: {
      const payload = action.payload;
      return {
        ...state,
        updatingProfile: true,
        error: false,
        errormessage: "",
        success: false,
        successmessage: "",
      }
    }

    case profile.UPDATE_SETTINGS_FAILURE: {
      const payload = action.payload;
      return {
        ...state,
        updatingProfile: false,
        error: true,
        errormessage: payload.data
      }
    }

    case profile.UPDATE_SETTINGS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        updatingProfile: false,
        user: {
          ...state.user,
          ...payload.user
        },
        profileCompleted: true,
        success: true,
        successmessage: payload.data
      }
    }

    case profile.DELETE_CARD: {
      return {
        ...state,
        updating: true,
        updatingCard: true,
        updatingMessage: "Removing payment source",
        error: false,
        errormessage: "",
        success: false,
        successmessage: ""
      }
    }

    case profile.DELETE_CARD_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        updating: false,
        updatingCard: false,
        card: payload,
        success: true,
        successmessage: payload.success
      }
    }

    case profile.DELETE_CARD_FAILURE: {
      const payload = action.payload;
      return {
        ...state,
        updating: false,
        updatingCard: false,
        error: true,
        errormessage: payload,
      }
    }

    case profile.SHOW_CARD_FROM: {
      return {
        ...state,
        showCardForm: true
      }
    }

    case profile.CLOSE_CARD_FROM: {
      return {
        ...state,
        showCardForm: false
      }
    }

    case profile.GET_QRCODE_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        qrcode: payload.data,
        mfasecret: payload.secret
      }
    }

    case profile.VALIDATE_QRCODE_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        qrcodeerror: !payload.data.valid,
        user: {
          ...state.user,
          mfa: payload.data.valid,
        }
      }
    }

    case profile.DISABLE_MFA_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        qrcodeerror: false,
        user: {
          ...state.user,
          mfa: false,
        }
      }
    }

    case profile.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getProfile = (state: State) => state;
export const getInvoices = (state: State) => state.invoices;
export const getShowCardForm = (state: State) => state.showCardForm;
export const getQRCode = (state: State) => state.qrcode;
export const getSecret = (state: State) => state.mfasecret;
export const getQRCodeError = (state: State) => state.qrcodeerror;
export const getMFA = (state: State) => state.user && state.user.mfa;

import {of as observableOf,  Observable } from 'rxjs';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import * as Action from '../store/actions';
import { MediaService } from '../media.service';
import * as fromMedia from './index';
import * as _ from 'lodash';
import mixpanel from 'mixpanel-browser/src/loader-module';

@Injectable()
export class Effects {

    /* Find media with ID */

    @Effect()
    findMedia: Observable<Action.FindMediaFailure | Action.FindMediaSuccess> = this.actions$
    .ofType(Action.FIND_MEDIA).pipe(
    withLatestFrom(this.store.select(state => state.media.media.media)),
    switchMap((data) => {
      let action = <Action.FindMedia> data[0];
      let media = data[1];

      const sequenceId = action.payload;
      const refresh = action.refresh;
      const filter = action.filter;

      if(!refresh && media.length) {
        const sequence = _.find(media, (item) => {
          return item.id == sequenceId
        });

        if(sequence) {
          return observableOf(new Action.FindMediaSuccess(sequence));
        }
      }

      // Check if sequence is in media array.
      return this.mediaService.findMedia(sequenceId).pipe(map((media) => {

        // We make sure it's filtered accordingly.
        // If pressing the refresh button on sequence overview,
        // it will return all elements. (including not filtered).

        const instances = filter ? filter.instances : [];
        let mediaFiltered = media.data;
        if(instances.length > 0) {
          mediaFiltered = {
            ...media,
            images: media.images.filter(i => {
              const cameraId = i.metadata.camera_id;
              if (instances.indexOf(cameraId) > -1){
                return true;
              }
              return false;
            })
          }
        }

        return new Action.FindMediaSuccess(mediaFiltered);
      }),catchError((e) => {
        return observableOf(new Action.FindMediaFailure(e.error.error));
      }),);
    }),);

    /* Find vault with ID */

    @Effect()
    findVault: Observable<Action.FindVaultFailure | Action.FindVaultSuccess> = this.actions$
    .ofType(Action.FIND_VAULT).pipe(
    withLatestFrom(this.store.select(state => state.media.media.vault)),
    switchMap((data) => {
      let action = <Action.FindVault> data[0];
      let media = data[1];

      const payload = action.payload;
      const sequenceId = action.payload.sequence;
      const mediaId = action.payload.media;

      if(media.length) {
        const vault = _.find(media, (item) => {
          return item.metadata.sequence_id == sequenceId && item.metadata.media_id == mediaId
        });

        if(vault) {
          return observableOf(new Action.FindVaultSuccess(vault));
        }
      }

      // Check if sequence is in media array.
      return this.mediaService.findVault(sequenceId, mediaId).pipe(map((media) => {
        return new Action.FindVaultSuccess(media);
      }),catchError((e) => {
        return observableOf(new Action.FindVaultFailure(e.error.error));
      }),);
    }),);

    /* Retrieve all media from current user */

    @Effect()
    requestMedia: Observable<Action.GetMediaFailure | Action.GetMediaSuccess> = this.actions$
        .ofType(Action.GET_MEDIA).pipe(
        switchMap((action: Action.GetMedia) => {
          return this.mediaService.getMedia(action.payload).pipe(map((media) => {
            return new Action.GetMediaSuccess(media.data);
          }),catchError((e) => {
            return observableOf(new Action.GetMediaFailure(e.error.error));
          }),);
        }));

    /* Retrieve hours from a specific day */

    @Effect()
    requestHours: Observable<Action.GetHoursFailure | Action.GetHoursSuccess> = this.actions$
        .ofType(Action.GET_HOURS).pipe(
        switchMap((action: Action.GetHours) => {
          return this.mediaService.getHours(action.payload).pipe(map((hours) => {
            return new Action.GetHoursSuccess(hours.data);
          }),catchError((e) => {
            return observableOf(new Action.GetHoursFailure(e.error.error));
          }),);
        }));

    /* Retrieve analysis from media */

    @Effect()
    requestAnalysis: Observable<Action.GetAnalysisFailure | Action.GetAnalysisSuccess> = this.actions$
        .ofType(Action.GET_ANALYSIS).pipe(
        switchMap((action: Action.GetAnalysis) => {
          return this.mediaService.getAnalysis(action.payload).pipe(map((analysis) => {
            return new Action.GetAnalysisSuccess(analysis);
          }),catchError((e) => {
            return observableOf(new Action.GetAnalysisFailure(e.error.error));
          }),);
        }));

    /* Star a sequence */

    @Effect()
    starSequence: Observable<Action.StarSequenceFailure | Action.StarSequenceSuccess> = this.actions$
        .ofType(Action.STAR_SEQUENCE).pipe(
        switchMap((action: Action.StarSequence) => {
          const id = action.payload.id;
          const star = action.payload.star;
          return this.mediaService.starSequence(id, star).pipe(map(() => {
            //mixpanel.track("starSequence", {star: action.payload});
            return new Action.StarSequenceSuccess({id, star});
          }),catchError((e) => {
            //mixpanel.track("starSequenceFailed", {star: action.payload, error: e});
            return observableOf(new Action.StarSequenceFailure(e.error.error));
          }),);
        }));

    /* Delete a sequence */

    @Effect()
    deleteSequence: Observable<Action.DeleteSequenceFailure | Action.DeleteSequenceSuccess> = this.actions$
        .ofType(Action.DELETE_SEQUENCE).pipe(
        switchMap((action: Action.DeleteSequence) => {
          const id = action.payload.id;
          return this.mediaService.deleteSequence(id).pipe(map(() => {
            //mixpanel.track("deleteSequence", {delete: action.payload});
            return new Action.DeleteSequenceSuccess({id});
          }),catchError((e) => {
            //mixpanel.track("deleteSequenceFailed", {delete: action.payload, error: e});
            return observableOf(new Action.DeleteSequenceFailure(e.error.error));
          }),);
        }));

    /* Add a label to media */

    @Effect()
    addLabel: Observable<Action.AddLabelFailure | Action.AddLabelSuccess> = this.actions$
        .ofType(Action.ADD_LABEL).pipe(
        switchMap((action: Action.AddLabel) => {
          const sequenceId = action.payload.sequenceId;
          const mediaId = action.payload.mediaId;
          const label = action.payload.label;
          return this.mediaService.addLabel(sequenceId, mediaId, label).pipe(map(() => {
            //mixpanel.track("addLabel", {label: action.payload});
            return new Action.AddLabelSuccess(action.payload);
          }),catchError((e) => {
            //mixpanel.track("addLabelFailed", {label: action.payload, error: e});
            return observableOf(new Action.AddLabelFailure(e.error.error));
          }),);
        }));

    /* Remove a label from media */

    @Effect()
    removeLabel: Observable<Action.RemoveLabelFailure | Action.RemoveLabelSuccess> = this.actions$
        .ofType(Action.REMOVE_LABEL).pipe(
        switchMap((action: Action.RemoveLabel) => {

          const sequenceId = action.payload.sequenceId;
          const mediaId = action.payload.mediaId;
          const labelId = action.payload.labelId;
          return this.mediaService.removeLabel(sequenceId, mediaId, labelId).pipe(map(() => {
            //mixpanel.track("removeLabel", {label: action.payload});
            return new Action.RemoveLabelSuccess(action.payload);
          }),catchError((e) => {
            //mixpanel.track("removeLabelFailed", {label: action.payload, error: e});
            return observableOf(new Action.RemoveLabelFailure(e.error.error));
          }),);
        }));

    /* Add a label to media */

    @Effect()
    updateDescription: Observable<Action.UpdateDescriptionFailure | Action.UpdateDescriptionSuccess> = this.actions$
        .ofType(Action.UPDATE_DESCRIPTION).pipe(
        switchMap((action: Action.UpdateDescription) => {
          const sequenceId = action.payload.sequenceId;
          const mediaId = action.payload.mediaId;
          const description = action.payload.description;
          return this.mediaService.updateDescription(sequenceId, mediaId, description).pipe(map(() => {
            //mixpanel.track("updateDescription", {description: action.payload});
            return new Action.UpdateDescriptionSuccess(action.payload);
          }),catchError((e) => {
            //mixpanel.track("updateDescriptionFailed", {description: action.payload, error: e});
            return observableOf(new Action.UpdateDescriptionFailure(e.error.error));
          }),);
        }));

    /* Retrieve all days from current user */

    @Effect()
    requestDays: Observable<Action.GetDaysFailure | Action.GetDaysSuccess> = this.actions$
        .ofType(Action.GET_DAYS).pipe(
        switchMap((action: Action.GetDays) => {
          return this.mediaService.getDays().pipe(map((days) => {
            let formattedDays = [];
            for(let i = 0; i < days.length; i++) {
              let day = days[i];
              formattedDays.push(day.substr(6,4) + "-" + day.substr(3,2) + "-" + day.substr(0,2));
            }

            formattedDays.sort((a, b) => {
              return new Date(b).getTime() - new Date(a).getTime();
            });

            return new Action.GetDaysSuccess(formattedDays);
          }),catchError((e) => {
            return observableOf(new Action.GetDaysFailure("failed getting days"));
          }),);
        }));

    /* Add to vault */

    @Effect()
    addToVault: Observable<Action.AddToVaultFailure | Action.AddToVaultSuccess> = this.actions$
        .ofType(Action.ADD_TO_VAULT).pipe(
        switchMap((action: Action.AddToVault) => {
          const sequenceId = action.payload.sequenceId;
          const mediaId = action.payload.mediaId;
          return this.mediaService.addToVault(sequenceId, mediaId).pipe(map(() => {
            //mixpanel.track("addToVault", {sequenceId, mediaId});
            return new Action.AddToVaultSuccess({sequenceId, mediaId});
          }),catchError((e) => {
            //mixpanel.track("addToVaultFailed", {sequenceId, mediaId, error: e});
            return observableOf(new Action.AddToVaultFailure(e.error.error));
          }),);
        }));

    /* Remove from vault */

    @Effect()
    removeFromVault: Observable<Action.RemoveFromVaultFailure | Action.RemoveFromVaultSuccess> = this.actions$
        .ofType(Action.REMOVE_FROM_VAULT).pipe(
        switchMap((action: Action.RemoveFromVault) => {
          const sequenceId = action.payload.sequenceId;
          const mediaId = action.payload.mediaId;
          return this.mediaService.removeFromVault(sequenceId, mediaId).pipe(map(() => {
            //mixpanel.track("removeFromVault", {sequenceId, mediaId});
            return new Action.RemoveFromVaultSuccess({sequenceId, mediaId});
          }),catchError((e) => {
            //mixpanel.track("removeFromVault", {sequenceId, mediaId, error: e});
            return observableOf(new Action.RemoveFromVaultFailure(e.error.error));
          }),);
        }));

    /* Retrieve the vault from thee current user */

    @Effect()
    requestVault: Observable<Action.GetVaultFailure | Action.GetVaultSuccess> = this.actions$
        .ofType(Action.GET_VAULT).pipe(
        switchMap((action: Action.GetVault) => {
          return this.mediaService.getVault().pipe(map((vault) => {
            return new Action.GetVaultSuccess(vault);
          }),catchError((e) => {
            return observableOf(new Action.GetVaultFailure(e.error.error));
          }),);
        }));

     /* Share media */

     @Effect()
     shareMedia: Observable<Action.ShareMediaFailure | Action.ShareMediaSuccess> = this.actions$
         .ofType(Action.SHARE_MEDIA).pipe(
         switchMap((action: Action.ShareMedia) => {
           const payload = action.payload;
           return this.mediaService.shareMedia(payload).pipe(map(() => {
             //mixpanel.track("addLabel", {label: action.payload});
             return new Action.ShareMediaSuccess(action.payload);
           }),catchError((e) => {
             //mixpanel.track("addLabelFailed", {label: action.payload, error: e});
             return observableOf(new Action.ShareMediaFailure(e.error.error));
           }),);
         }));

    constructor(private mediaService: MediaService,
                private store: Store<fromMedia.State>,
                private actions$: Actions) {}
}
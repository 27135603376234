
import {take, map} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiService } from '../../../../../shared/api.service';
declare var videojs : any;

@Component({
  selector: 'VideoHeaderModalComponent',
  templateUrl: './videoheadermodal.component.html',
  styleUrls: ['./videoheadermodal.component.scss']
})
export class VideoHeaderModalComponent {

  player: any;
  sequenceLoaded: boolean;

  constructor(public dialogRef: MatDialogRef<VideoHeaderModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private http: HttpClient,
              private apiService: ApiService) {
    this.sequenceLoaded = false;
  }

  ngAfterViewInit() {

    if(this.data){

      const sequenceId = this.data;

      this.http.get(this.apiService.findMedia(sequenceId)).pipe(map((sequence) => {

        this.sequenceLoaded = true;

        this.player = videojs(document.getElementById('sequence'),  {
          controlBar: {
            volumeMenuButton: false
          },
          fluid: true
        });

        this.player.playlistUi();

        //let brainButton = this.player.controlBar.addChild('Button');
        //brainButton.addClass('vjs-brain-button');

        let playlist = [];
        for(let i = 0; i < sequence['images'].length; i++)
        {
            const media = sequence['images'][i];
            if(media.type === "video") {

                const instance = media.metadata.instanceName;
                let name = media.time + " (" + instance + ")";

                if(media.analysis &&
                   media.analysis.classification &&
                   media.analysis.classification.properties &&
                   media.analysis.classification.properties.length > 0
                 ) {
                  const properties = media.analysis.classification.properties;
                  const property = properties[0];
                  name = property.charAt(0).toUpperCase() + property.slice(1) + " at " + name;
                }

                const video = {
                  name,
                  sources: [{
                      src: media.src,
                      type: 'video/mp4',
                    }]
                }

                playlist.push(video);
            }
        }

        this.player.playlist(playlist);
        this.player.playlist.first();
        this.player.play();

      }),take(1),).subscribe();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

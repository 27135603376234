import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import * as svg from "../../../shared/svg.inject";

@Component({
  selector: "SearchField",
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchField implements OnInit, OnDestroy  {

  @Input("action") action: any;
  @Input("placeholder") placeholder: any;
  @Input("change") change: any;

  public searchValue: string = '';
  public state = {
    'active': false
  };

  constructor() {}

  ngOnInit() {

  }

  ngAfterViewInit(){
    svg.inject();
  }

  onChange(event) {
    this.change(this.searchValue);
  }

  ngOnDestroy(){
  }

  onFocus(){
    this.state.active = true;
  }

  onFocusOut(){
    this.state.active = false;
  }
}

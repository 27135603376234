import {of as observableOf,  Observable } from 'rxjs';
import {catchError, switchMap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as Action from '../store/actions';
import { State } from '../../../app.reducer';
import {DashboardService} from "../dashboard.service";

@Injectable()
export class Effects {

    /* Retrieve all dashboard figures from current user */

    @Effect()
    requestDashboard: Observable<Action.RequestDashboardFailure | Action.RequestDashboardSuccess> = this.actions$
        .ofType(Action.REQUEST_DASHBOARD).pipe(
        switchMap((action: Action.RequestDashboard) => {
          return this.dashboardService.getDashboard().pipe(map((dashboard) => {
            return new Action.RequestDashboardSuccess(dashboard);
          }),
          catchError(e => {
            return observableOf(new Action.RequestDashboardFailure(e));
          }),);
        }));

    constructor(private dashboardService: DashboardService,
                private store$: Store<State>,
                private actions$: Actions) {}
}


import {of as observableOf,  Observable } from 'rxjs';
import {catchError, switchMap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import * as Action from '../store/actions';
import { AccountsService } from '../accounts.service';
import { State } from '../../../app.reducer';

@Injectable()
export class Effects {

    /* Retrieve all sites */

    @Effect()
    requestAccounts: Observable<Action.RequestAccountsFailure | Action.RequestAccountsSuccess> = this.actions$
        .ofType(Action.REQUEST_ACCOUNTS).pipe(
        switchMap((action: Action.RequestAccounts) => {
          return this.accountsService.getAccounts().pipe(map((accounts) => {
            return new Action.RequestAccountsSuccess(accounts);
          }),
          catchError(e => {
            return observableOf(new Action.RequestAccountsFailure(e));
          }),);
        }));

    /* Add account */

    @Effect()
    addAccount: Observable<Action.AddAccountFailure | Action.AddAccountSuccess> = this.actions$
        .ofType(Action.ADD_ACCOUNT).pipe(
        switchMap((action: Action.AddAccount) => {
          return this.accountsService.addAccount(action.payload).pipe(map((accounts) => {
            return new Action.AddAccountSuccess(accounts);
          }),
          catchError(e => {
            return observableOf(new Action.AddAccountFailure(e.error.message));
          }),);
        }));

    /* Edit account */

    @Effect()
    editAccount: Observable<Action.EditAccountFailure | Action.EditAccountSuccess> = this.actions$
        .ofType(Action.EDIT_ACCOUNT).pipe(
        switchMap((action: Action.EditAccount) => {
          return this.accountsService.editAccount(action.id, action.payload).pipe(map((accounts) => {
            return new Action.EditAccountSuccess(accounts);
          }),
          catchError(e => {
            return observableOf(new Action.EditAccountFailure(e.error.message));
          }),);
        }));

    /* Delete account */

    @Effect()
    deleteAccount: Observable<Action.DeleteAccountFailure | Action.DeleteAccountSuccess> = this.actions$
        .ofType(Action.DELETE_ACCOUNT).pipe(
        switchMap((action: Action.DeleteAccount) => {
          return this.accountsService.deleteAccount(action.id).pipe(map((accounts) => {
            return new Action.DeleteAccountSuccess(accounts);
          }),
          catchError(e => {
            return observableOf(new Action.DeleteAccountFailure(e));
          }),);
        }));

    constructor(private accountsService: AccountsService,
                private store$: Store<State>,
                private actions$: Actions,
                private router: Router,
                private route: ActivatedRoute) {}
}

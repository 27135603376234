import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DatePipe} from "@angular/common";
import { not } from '@angular/compiler/src/output/output_ast';
const moment = require('moment');

@Component({
  selector: "WatchList",
  templateUrl: './watch-list.component.html',
  styleUrls: ['./watch-list.component.scss'],
})
export class WatchList implements OnChanges  {

  @Input() notifications: any;
  @Input() role: string = "guest";
  @Input() addTask: any
  @Input() editTask: any
  @Input() deleteTask: any
  @Input() markAsRead: any
  @Input() share: any
  public allNotifications = [];
  public groupedNotifications = {};
  public notificationDays = [];

  ngOnChanges(changes: SimpleChanges) {
    if(changes.notifications) {
      this.allNotifications = [];
      this.groupedNotifications = {};
      this.notificationDays = [];
      const notifications = changes.notifications.currentValue;
      if(notifications) {
        for(let i = 0; i < notifications.length; i++) {
          const notification = notifications[i];
          const date_short =  notification.media_dateshort;
          const n = {
            id: notification.id,
            read: notification.read,
            task_created: notification.task_created,
            sequence_id: notification.sequence_id,
            timestamp: notification.timestamp,
            title: notification.body,
            notes: notification.alert_name,
            media_key: notification.media_key,
            media_provider: notification.media_provider,
            media_source: notification.media_source,
            titleShort: this.truncate(notification.body, 20, "..."),
            notesShort: this.truncate(notification.alert_name, 50, "..."),
            thumbnail: notification.thumbnail,
            date_short,
            media_date: notification.media_date,
            media_datetime: notification.media_datetime,
            file_url: notification.file_url, 
            thumbnail_url: notification.thumbnail_url,
            sprite_url: notification.sprite_url,
            camera: notification.camera,
            cameraId: notification.device_id,
            cameraName: notification.device_name,
            sites: notification.sites,
            groups: notification.groups
          };
          this.allNotifications.push(n);
          if(this.groupedNotifications[date_short]){
            this.groupedNotifications[date_short].push(n);
          } else {
            this.groupedNotifications[date_short] = [n];
            this.notificationDays.push(date_short)
          }
        }
      }
    }
  }

  public trackNotification(index: number, task: any) {
    return task.id;
  }

  public truncate(fullStr, strLen, separator) {
    if(!fullStr || fullStr === "") return "";
    if (fullStr.length <= strLen) return fullStr;
    separator = separator || '...';
    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow/2),
      backChars = Math.floor(charsToShow/2);
    return fullStr.substr(0, frontChars) +
      separator +
      fullStr.substr(fullStr.length - backChars);
  }

  public shortenString(text: string, length: number) : string {
    const trimmedString = text.length > length ? text.substring(0, length - 3) : text;
    return trimmedString
  }
}

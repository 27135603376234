
import {map, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiService } from '../../shared/api.service';

@Injectable()
export class MediaService {

  constructor(private http: HttpClient,
              private apiService: ApiService) {}

  public getAnalysis(id) : Observable<any> {
    return this.http.get(this.apiService.getAnalysis(id)).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getMedia(filter) : Observable<any> {
    return this.http.post(this.apiService.getMedia(), filter).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public findMedia(id) : Observable<any> {
    return this.http.get(this.apiService.findMedia(id)).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public findVault(id, media) : Observable<any> {
    return this.http.get(this.apiService.findVault(id, media)).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public addLabel(id, media, label: string) : Observable<any> {
    return this.http.post(this.apiService.addLabel(id, media), {label}).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public removeLabel(id, media, label) : Observable<any> {
    return this.http.delete(this.apiService.removeLabel(id, media, label)).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public updateDescription(id, media, description: string) : Observable<any> {
    return this.http.post(this.apiService.updateDescription(id, media), {description}).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public starSequence(id, star: boolean) : Observable<any> {
    return this.http.post(this.apiService.starSequence(id), {star}).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public deleteSequence(id) : Observable<any> {
    return this.http.delete(this.apiService.deleteSequence(id)).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getDays() : Observable<any> {
    return this.http.get(this.apiService.getDays()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getHours(day) : Observable<any> {
    return this.http.get(this.apiService.getHours(day)).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getVault() : Observable<any> {
    return this.http.get(this.apiService.getVault()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public addToVault(id, media) : Observable<any> {
    return this.http.put(this.apiService.addToVault(id, media), {}).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public removeFromVault(id, media) : Observable<any> {
    return this.http.delete(this.apiService.removeFromVault(id, media)).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public shareMedia(payload: any) : Observable<any> {
    return this.http.post(this.apiService.shareMedia(), payload).pipe(take(1),map(value => {
      return value;
    }),);
  }
}

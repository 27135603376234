import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';

import * as fromAuth from './auth';
import * as fromLoginPage from './login-page';

export * from './effects';

export interface AuthState {
  status: fromAuth.State;
  loginPage: fromLoginPage.State;
}

export interface State extends fromRoot.State {
  auth: AuthState;
}

export const Reducers = {
  status: fromAuth.reducer,
  loginPage: fromLoginPage.reducer,
};

export const selectAuthState = createFeatureSelector<AuthState>('auth');
export const selectAuthStatusState = createSelector(selectAuthState, (state: AuthState) => state.status);
export const getLoginState = createSelector(selectAuthStatusState, fromAuth.getLoginState);
export const getLoggedIn = createSelector(selectAuthStatusState, fromAuth.getLoggedIn);
export const getRegistered = createSelector(selectAuthStatusState, fromAuth.getRegistered);
export const getRegisterPending = createSelector(selectAuthStatusState, fromAuth.getRegisterPending);
export const getRegisteredError = createSelector(selectAuthStatusState, fromAuth.getRegisteredError);
export const getForgot = createSelector(selectAuthStatusState, fromAuth.getForgot);
export const getForgotPending = createSelector(selectAuthStatusState, fromAuth.getForgotPending);
export const getForgotError = createSelector(selectAuthStatusState, fromAuth.getForgotError);
export const getActivateStatus = createSelector(selectAuthStatusState, fromAuth.getActivateStatus);
export const getActivatePending = createSelector(selectAuthStatusState, fromAuth.getActivatePending);
export const getUser = createSelector(selectAuthStatusState, fromAuth.getUser);

export const selectLoginPageState = createSelector(selectAuthState, (state: AuthState) => state.loginPage);
export const getLoginPageError = createSelector(selectLoginPageState, fromLoginPage.getError);
export const getLoginPagePending = createSelector(selectLoginPageState, fromLoginPage.getPending);
export const getMFARequired = createSelector(selectLoginPageState, fromLoginPage.getMFARequired);
